import httpService from "services/httpService";

const baseApiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const apiEndPoint = baseApiEndPoint + "/shipping_cost";

export async function updateAdditionalPayment(model) {
  const { data } = await httpService.post(apiEndPoint, {
    ...model,
  });
  return data;
}

export async function fetchAdditionalPaymentTypeList() {
  const { data } = await httpService.get(apiEndPoint + "/types");
  return data;
}

export default {
  updateAdditionalPayment,
  fetchAdditionalPaymentTypeList,
};
