import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  pagination,
  renderFormPanel,
  showCouldNotLoadRecordsMessage,
  showLoadingRecordsMessage,
  showNoRecordsMessage,
  renderFieldGrouping,
} from "config/config";
import { Badge, Card, Dropdown, FormGroup, Spinner } from "reactstrap";
import Container from "reactstrap/lib/Container";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Button from "reactstrap/lib/Button";
import ReactToPrint from "react-to-print";
import TableActionButtons from "../admin/TableActionButtons";
import Modal from "reactstrap/lib/Modal";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import DropdownItem from "reactstrap/lib/DropdownItem";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import shipmentService from "services/shipmentService";
import fileDownloadService from "services/fileDownloadService";
import auth from "services/authService";
import utils from "util/CustomUtil";
import CompleteBooking from "./bookshipment/CompleteBooking";
import Timeline from "./timeline/Timeline";
import BookShipment from "./BookShipment";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { WP_ADDRESSES, COURIER } from "constants/constants";
import Joi from "joi-browser";

const dateFormat = "YYYY-MM-DD";
class ShippingHistory extends ValidationForm {
  state = {
    data: {
      shippingDocumentTypeList: [],
    },
    errors: {},
    formSubmitted: true,
    isLoading: false,
    startDate: "",
    endDate: "",
    history: [],
    historyDetailModal: false,
    currentHistory: null,
    searchTriggered: false,
    fetchingDetail: false,
    moveToCompleteBooking: false,
    completeBookingShipmentId: 0,
    trackingModal: false,
    fetchingTrackingInfo: false,
    trackingDetail: null,
    voidShipmentModal: false,
    voidShipmentInProgress: false,
    voidShipmentDetail: null,
    uploadClearanceDocumentModal: false,
    uploadClearanceDocumentInProgress: false,
    uploadClearanceDocumentShipmentDetail: null,
    uploadClearanceDocumentType: null,
    uploadClearanceDocumentFormData: null,
    uploadClearanceDocumentFilename: null,
    shippingDocumentTypeList: [],
    fileProperties: {
      maxAllowedFileSize: 2097152,
      mimeTypes: ["application/pdf"],
    },
    isVoidAndEditShipment: false,
  };

  schema = {
    shippingDocumentTypeList: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Origin Address List"),
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: null,
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else {
      this.setState({
        [who]: date,
      });
    }
  };
  // this function adds on the day tag of the date picker
  // middle-date className which means that this day will have no border radius
  // start-date className which means that this day will only have left border radius
  // end-date className which means that this day will only have right border radius
  // this way, the selected dates will look nice and will only be rounded at the ends
  getClassNameReactDatetimeDays = (date) => {
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  async componentDidMount() {
    try {
      this.setState({ formSubmitted: true, searchTriggered: true });
      const history = await shipmentService.fetchShipmentHistoryRecentRecord();
      await this.updateShippingDocumentTypeList();
      this.setState({ formSubmitted: false, history });
    } catch (ex) {
      this.setState({ formSubmitted: false });
    }
  }

  async updateShippingDocumentTypeList() {
    try {
      const docTypeList = await shipmentService.fetchShippingDocumentTypeList();

      const shippingDocumentTypeList = [];
      this.shippingDocumentTypeList = [];
      docTypeList.map((type) => {
        shippingDocumentTypeList[shippingDocumentTypeList.length] = {
          value: type,
          label: type,
        };
      });

      this.setState({
        shippingDocumentTypeList,
      });
    } catch (ex) {}
  }

  async loadTrackingDetail(shipmentDetail) {
    this.setState({ fetchingTrackingInfo: true, trackingDetail: null });
    const trackingDetail = await shipmentService.trackShipment(
      shipmentDetail.trackingNumber
    );
    trackingDetail.id = shipmentDetail.id;
    trackingDetail.clearanceDocuments = shipmentDetail.clearanceDocuments;
    this.setState({
      fetchingTrackingInfo: false,
      trackingDetail,
    });
  }

  render() {
    if (this.state.editShipment) {
      return (
        <BookShipment editShipmentId={this.state.editShipmentId}></BookShipment>
      );
    }

    let { isLoading } = this.state;
    let recordViewComponent;
    if (this.state.formSubmitted) {
      recordViewComponent = showLoadingRecordsMessage(
        "Loading your past shipments... Please wait!"
      );
    } else if (this.state.couldNotLoadTableRecords) {
      recordViewComponent = showCouldNotLoadRecordsMessage(
        "Could not load shipping history. Please try again."
      );
    } else {
      recordViewComponent =
        this.state.history.length > 0
          ? this.loadTable()
          : showNoRecordsMessage(
              this.state.searchTriggered
                ? "No records found! You may need to modify the search date."
                : "Your search results will show up here!"
            );
    }

    return (
      <>
        {this.historyDetailModal()}
        {this.trackingModal()}
        {this.voidShipmentModal()}
        {this.uploadClearanceDocumentModal()}

        <SimpleHeader
          name="Past Shipments"
          parentName="Tables"
          description="View all your past shipments."
        />
        <Container className="mt--6" fluid>
          {this.state.moveToCompleteBooking ? (
            this.moveToCompleteBooking()
          ) : (
            <>
              <form onSubmit={this.handleSubmit}>
                <Row>
                  <Col xs={12} sm={4} className="offset-md-1">
                    <label className=" form-control-label">Start date</label>
                    <FormGroup>
                      <ReactDatetime
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          placeholder: "Date Picker Here",
                        }}
                        value={this.state.startDate}
                        timeFormat={false}
                        onChange={(e) =>
                          this.handleReactDatetimeChange("startDate", e)
                        }
                        renderDay={(props, currentDate, selectedDate) => {
                          let classes = props.className;
                          classes +=
                            this.getClassNameReactDatetimeDays(currentDate);
                          return (
                            <td {...props} className={classes}>
                              {currentDate.date()}
                            </td>
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={4}>
                    <FormGroup>
                      <label className=" form-control-label">End date</label>
                      <ReactDatetime
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          placeholder: "Date Picker Here",
                        }}
                        value={this.state.endDate}
                        timeFormat={false}
                        onChange={(e) =>
                          this.handleReactDatetimeChange("endDate", e)
                        }
                        renderDay={(props, currentDate, selectedDate) => {
                          let classes = props.className;
                          classes +=
                            this.getClassNameReactDatetimeDays(currentDate);
                          return (
                            <td {...props} className={classes}>
                              {currentDate.date()}
                            </td>
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    className="col-lg-2 align-self-center pt-2"
                    xs={12}
                    sm={3}
                    md={2}
                  >
                    <Button
                      size="md"
                      color="primary"
                      block
                      disabled={
                        !this.state.startDate ||
                        !this.state.endDate ||
                        this.state.formSubmitted
                      }
                      onClick={async (e) => {
                        e.preventDefault();
                        this.setState({
                          searchTriggered: true,
                          formSubmitted: true,
                          history: [],
                        });
                        const history =
                          await shipmentService.fetchShipmentHistory(
                            this.state.startDate.format(dateFormat),
                            this.state.endDate.format(dateFormat)
                          );
                        this.setState({ formSubmitted: false, history });
                      }}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </form>
              <Row>
                <div className="col mt-4">{recordViewComponent}</div>
              </Row>
            </>
          )}
        </Container>
      </>
    );
  }

  handleSubmit() {}

  loadTrackingNumbers(courier) {
    if (!this.isAdminRole()) {
      return;
    }

    // https://www.fedex.com/fedextrack/?trknbr=794624021369

    const trackingNumbersDisplay =
      this.state.trackingDetail.trackingNumbers.map((tn, index) => {
        return (
          <Badge color="default">
            {(courier === COURIER.FEDEX ||
              courier === COURIER.FEDEX_PRIORITY) && (
              <a
                href={`https://www.fedex.com/fedextrack/?trknbr=${tn}`}
                target="_blank"
                className="text-white font-weight-bold text-underline"
              >
                {tn}
              </a>
            )}
            {courier === COURIER.UPS && (
              <a
                href={`https://www.ups.com/WebTracking/processInputRequest?tracknum=${tn}&loc=en_NG&requester=ST/trackdetails`}
                target="_blank"
                className="text-white font-weight-bold text-underline"
              >
                {tn}
              </a>
            )}
            {courier === COURIER.DHL && (
              <a
                href={`https://mydhl.express.dhl/us/en/tracking.html#/results?id=${tn}`}
                target="_blank"
                className="text-white font-weight-bold text-underline"
              >
                {this.state.trackingDetail.waybillNumber} / {tn}
              </a>
            )}
            {(courier === COURIER.ARAMEX ||
              courier === COURIER.ARAMEX_PREMIUM) && (
              <a
                href={`https://www.aramex.com/sa/en/track/track-results-new?ShipmentNumber=${tn}`}
                target="_blank"
                className="text-white font-weight-bold text-underline"
              >
                {this.state.trackingDetail.waybillNumber} / {tn}
              </a>
            )}
          </Badge>
        );
      });
    return <h2>{trackingNumbersDisplay}</h2>;
  }

  loadHistoryTableTrackingNumbers(
    courier,
    waybillNumber,
    trackingNumbers,
    status
  ) {
    if (this.hidePartnerTrackingNumber(status)) {
      return;
    }

    const trackingNumbersDisplay = trackingNumbers.map((tn, index) => {
      return (
        <>
          <br />
          {(courier === COURIER.FEDEX ||
            courier === COURIER.FEDEX_PRIORITY) && (
            <a
              href={`https://www.fedex.com/fedextrack/?trknbr=${tn}`}
              target="_blank"
              className="text-primary font-weight-bold text-underline"
            >
              {tn}
            </a>
          )}
          {courier === COURIER.UPS && (
            <a
              href={`https://www.ups.com/WebTracking/processInputRequest?tracknum=${tn}&loc=en_NG&requester=ST/trackdetails`}
              target="_blank"
              className="text-primary font-weight-bold text-underline"
            >
              {tn}
            </a>
          )}
          {courier === COURIER.DHL && (
            <a
              href={`https://mydhl.express.dhl/us/en/tracking.html#/results?id=${tn}`}
              target="_blank"
              className="text-primary font-weight-bold text-underline"
            >
              {waybillNumber} / {tn}
            </a>
          )}
          {(courier === COURIER.ARAMEX ||
            courier === COURIER.ARAMEX_PREMIUM) && (
            <a
              href={`https://www.aramex.com/sa/en/track/track-results-new?ShipmentNumber=${tn}`}
              target="_blank"
              className="text-primary font-weight-bold text-underline"
            >
              {tn}
            </a>
          )}
        </>
      );
    });
    return <>{trackingNumbersDisplay}</>;
  }

  moveToCompleteBooking() {
    return (
      <CompleteBooking
        shipment={this.state.shipment}
        returnAction={() => {
          this.setState({ moveToCompleteBooking: false });
        }}
        returnMessage_1="Return to Shipping History"
        returnMessage_2="Return to Shipping History"
        shipmentId={this.state.completeBookingShipmentId}
      />
    );
  }

  hidePartnerTrackingNumber(status) {
    return (
      !this.isAdminRole() ||
      !(
        status === "BOOKED" ||
        status === "COURIER_ASSIGNED" ||
        status === "DELIVERED"
      )
    );
  }

  loadTable() {
    return (
      <Card>
        <ToolkitProvider
          data={this.state.history}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
            },
            {
              dataField: "requestDate",
              text: "Date",
              sort: true,
              formatter: (cell, row) => {
                if (this.isAdminRole()) {
                  return (
                    <>
                      <span className="text-primary font-weight-bold">DC:</span>{" "}
                      {row.requestDate}
                      <br />
                      {row.paymentNotificationDate && (
                        <>
                          <span className="wirepackage-text-success font-weight-bold">
                            PD:
                          </span>
                        </>
                      )}
                      {row.paymentNotificationDate
                        ? row.paymentNotificationDate
                        : ""}
                    </>
                  );
                } else {
                  return row.requestDate;
                }
              },
              filterValue: (cell, row) => {
                return row.requestDate;
              },
            },
            {
              dataField: "serviceType",
              text: "Service",
              sort: true,
              formatter: (cell, row) => {
                return (
                  <>
                    <h3>
                      <Badge color="primary">{row.serviceType}</Badge>
                    </h3>
                  </>
                );
              },
              filterValue: (cell, row) => {
                return row.serviceType;
              },
            },
            {
              dataField: "trackingNumber",
              text: "Tracking No.",
              sort: true,
              formatter: (cell, row) => {
                return (
                  <>
                    {row.trackingNumber}
                    {this.loadHistoryTableTrackingNumbers(
                      row.courier,
                      row.waybillNumber,
                      row.partnerTrackingNumbers,
                      row.status.toUpperCase()
                    )}
                    {this.displayItemClassificationIndicator(row)}
                    {this.checkDeliveryDelay(row)}
                  </>
                );
              },
              filterValue: (cell, row) => {
                let filterValues = row.trackingNumber;
                if (this.hidePartnerTrackingNumber(row.status.toUpperCase())) {
                  return filterValues;
                }

                if (row.waybillNumber) {
                  filterValues = filterValues + " " + row.waybillNumber;
                }

                return (
                  filterValues +
                  " " +
                  row.partnerTrackingNumbers.map((ptn) => {
                    return row.partnerTrackingNumbers + " ";
                  })
                );
              },
            },
            {
              dataField: "sender",
              text: "Sender",
              sort: true,
              formatter: (cell, row) => {
                return (
                  <>
                    {row.sender} <br />
                    <b className="text-primary">{row.originState}</b>
                  </>
                );
              },
              filterValue: (cell, row) => {
                return row.sender + " " + row.originState;
              },
            },
            {
              dataField: "recipient",
              text: "Recipient",
              sort: true,
              formatter: (cell, row) => {
                return (
                  <>
                    {row.recipient} <br />
                    <b className="text-primary">{row.destinationCountry}</b>
                  </>
                );
              },
              filterValue: (cell, row) => {
                return row.recipient + " " + row.destinationCountry;
              },
            },
            {
              dataField: "status",
              text: "Status",
              sort: true,
              filterValue: (cell, row) => {
                const status = row.status.toUpperCase();
                if (
                  this.isAdminRole() &&
                  row.inTransit &&
                  (status === "BOOKED" || status === "COURIER_ASSIGNED")
                ) {
                  return "ON THE WAY";
                } else return status;
              },
              formatter: (cell, row) => {
                return (
                  <>
                    {this.displayStatus(row.status, row.inTransit)}
                    {row.status.toUpperCase() ===
                      "AWAITING_PAYMENT_CONFIRMATION" && (
                      <>
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => {
                            this.setState({
                              moveToCompleteBooking: true,
                              completeBookingShipmentId: row.id,
                            });
                          }}
                        >
                          Complete Booking
                        </Button>
                        <Button
                          outline
                          color="danger"
                          size="sm"
                          onClick={async () => {
                            await shipmentService.cancelBooking(row.id);
                            // const incompleteBookings =
                            //   this.state.incompleteBookings;
                            // incompleteBookings.splice(index, 1);
                            // this.setState({ incompleteBookings });
                          }}
                        >
                          Ignore
                        </Button>
                      </>
                    )}
                  </>
                );
              },
            },
            {
              dataField: "menu",
              text: "",
              sort: false,
              formatter: (cell, row) =>
                this.loadTableContextMenu(this.toggleModal, row),
              classes: (cell, row, rowIndex, colIndex) => "px-0",
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <TableActionButtons {...props}>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      size="sm"
                      className="buttons-copy buttons-html5"
                      id="print-tooltip"
                    >
                      Print
                    </Button>
                  )}
                  content={() => this.componentRef}
                />
              </TableActionButtons>
              <BootstrapTable
                size="sm"
                condensed
                hover
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                id="react-bs-table"
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  displayStatus(status, inTransit) {
    status = status.toUpperCase();
    let statusColor = "";
    let classes = "";

    if (
      this.isAdminRole() &&
      inTransit &&
      (status === "BOOKED" || status === "COURIER_ASSIGNED")
    ) {
      statusColor = "info";
      classes = "text-indigo";
      status = "ON THE WAY";
    } else if (status === "BOOKED" || status === "COURIER_ASSIGNED") {
      statusColor = "default";
    } else if (status === "CANCELED" || status === "VOIDED") {
      statusColor = "danger";
    } else if (status === "PENDING") {
      statusColor = "secondary";
    } else if (status === "DELIVERED") {
      statusColor = "success";
    } else if (status === "PENDING_ISSUE") {
      statusColor = "warning";
    } else if (status === "AWAITING_PAYMENT_CONFIRMATION") {
      return "";
    }

    return (
      <h3>
        <Badge color={statusColor} className={classes}>
          {status}
        </Badge>
      </h3>
    );
  }

  checkDeliveryDelay(shipment) {
    if (this.isAdminRole() && shipment.deliveryDelay) {
      return (
        <div>
          <i class="fas fa-calendar-times text-lg text-warning"></i>
        </div>
      );
    }
  }

  displayItemClassificationIndicator(shipment) {
    if (this.isAdminRole() && shipment.clearanceDocuments) {
      let foodIcon = null;
      let medicationIcon = null;
      shipment.clearanceDocuments.map((clearanceDocument) => {
        if (clearanceDocument.classification == "MEDICATION") {
          medicationIcon = (
            <>
              <i
                class={`fas fa-capsules text-lg text-${
                  clearanceDocument.uploaded ? "gray" : "danger"
                }`}
              ></i>{" "}
            </>
          );
        } else if (
          !foodIcon &&
          (clearanceDocument.classification == "NATIVE_FOOD" ||
            clearanceDocument.classification == "FISH")
        ) {
          foodIcon = (
            <>
              <i
                class={`fas fa-fish text-lg mr-2 text-${
                  clearanceDocument.uploaded ? "gray" : "danger"
                }`}
              ></i>{" "}
            </>
          );
        }
      });
      return (
        <>
          <br />
          {foodIcon}
          {medicationIcon}
        </>
      );
    }
  }

  processPhytoOriginAddress(stateCode) {
    if (stateCode === "FC") {
      return WP_ADDRESSES.ABUJA;
    }

    if (stateCode === "OY") {
      return WP_ADDRESSES.OYO;
    }

    return WP_ADDRESSES.LAGOS;
  }

  historyDetailModal = () => {
    let clearanceDocumentIds = [];
    const currentHistory = this.state.currentHistory;
    let phytoRequestMessage = null;

    if (this.isAdminRole()) {
      let phytoRequestItemList = "\n\n*ITEM(s)*\n\n";
      let itemCount = 1;

      if (currentHistory) {
        let itemTrackingNumbers = "";
        currentHistory.items.map((it) => {
          itemTrackingNumbers = itemTrackingNumbers + it.waybillNumber + "\n";
        });

        this.state.currentHistory.items.map((item) => {
          item.itemUnits.map((itu) => {
            phytoRequestItemList =
              phytoRequestItemList +
              itemCount++ +
              ") " +
              itu.description +
              "\n";
          });
        });

        phytoRequestMessage =
          "*===== PHYTO REQUEST ====*\n\n" +
          "*Tracking Number(s)*\n" +
          itemTrackingNumbers +
          "\n*WEIGHT*\n" +
          this.state.currentHistory.items.map((item) => {
            return " " + item.weight + " Kg";
          }) +
          "\n\n" +
          "*SENDER*\n*Name:* " +
          currentHistory.origin.fullname +
          "\n" +
          "*Phone Number:* " +
          WP_ADDRESSES.CONTACT_NUMBER +
          "\n" +
          "*Address:* " +
          this.processPhytoOriginAddress(currentHistory.origin.state) +
          "\n\n" +
          "*RECEIVER*\n*Name:* " +
          currentHistory.destination.fullname +
          "\n" +
          "*Phone Number:* " +
          currentHistory.destination.phoneNumber +
          "\n" +
          "*Address:* " +
          currentHistory.destination.address +
          ", " +
          currentHistory.destination.stateName +
          ", " +
          currentHistory.destination.countryName +
          "\n*Postal Code:* " +
          currentHistory.destination.postalCode +
          phytoRequestItemList +
          "\n";

        const status = currentHistory.status.toUpperCase();
        if (currentHistory.clearanceDocuments) {
          currentHistory.clearanceDocuments.map((doc) => {
            if (
              !doc.uploaded &&
              (status === "BOOKED" || status === "COURIER_ASSIGNED")
            ) {
              clearanceDocumentIds[clearanceDocumentIds.length] = doc.id;
            }
          });
        }
      }
    }

    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.historyDetailModal}
        toggle={() => this.toggleModal("historyDetailModal")}
        backdrop="static"
        size="lg"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            Shipment Detail
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("historyDetailModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {this.state.fetchingDetail
            ? showLoadingRecordsMessage(
                "Loading shipment detail... Please wait."
              )
            : currentHistory && (
                <>
                  <div className="d-flex">
                    <div className="mr-auto">
                      {phytoRequestMessage && (
                        <CopyToClipboard
                          text={phytoRequestMessage}
                          onCopy={async () => {
                            toast.success("Phyto request copied.", {
                              toastId: "phytoMsg",
                            });

                            if (clearanceDocumentIds.length > 0) {
                              await shipmentService.clearanceDocumentInitiated({
                                clearanceDocumentIds,
                              });
                            }
                          }}
                        >
                          <Button
                            color="success"
                            type="button"
                            // onClick={() => this.toggleModal("regenerateTokenModal")}
                            size="sm"
                          >
                            Copy Phyto Request
                          </Button>
                        </CopyToClipboard>
                      )}
                    </div>
                    <Button
                      // className="ml-auto"
                      color="primary"
                      size="sm"
                      onClick={async () => {
                        const currentHistory = this.state.currentHistory;
                        this.loadTrackingDetail(currentHistory);
                        this.toggleModal("trackingModal");
                        this.toggleModal("historyDetailModal");
                      }}
                    >
                      <i class="fas fa-exchange-alt"></i> show tracking history
                    </Button>
                  </div>
                  <br />
                  <Row>
                    <Col className="col-12 col-lg-6">
                      {renderFormPanel({
                        title: "Sender",
                        content: (
                          <>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Fullname</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.fullname}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">Phone No:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.phoneNumber}
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>
                                <div className="text-muted">Email:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.email}
                                </div>
                              </Col>
                            </Row>
                            <div className="border-bottom border-light pb-2 mb-3" />
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Address:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.address}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">City</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.cityName}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">State:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.stateName}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Country</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.countryName}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">Postal Code:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.origin.postalCode}
                                </div>
                              </Col>
                            </Row>
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                    <Col className="col-12 col-lg-6">
                      {renderFormPanel({
                        title: "Recipient",
                        content: (
                          <>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Fullname</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.fullname}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">Phone No:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.phoneNumber}
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>
                                <div className="text-muted">Email:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.email}
                                </div>
                              </Col>
                            </Row>
                            <div className="border-bottom border-light pb-2 mb-3" />
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Address:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.address}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">City</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.cityName}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">State:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.stateName}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">
                                <div className="text-muted">Country</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.countryName}
                                </div>
                              </Col>
                              <Col className="mb-2">
                                <div className="text-muted">Postal Code:</div>
                                <div className="font-weight-bold">
                                  {currentHistory.destination.postalCode}
                                </div>
                              </Col>
                            </Row>
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {renderFormPanel({
                        title: "Item(s)",
                        content: (
                          <>
                            {this.state.currentHistory.items.map(
                              (item, index) => {
                                return (
                                  <>
                                    <div className="d-none d-md-block">
                                      <span className="font-weight-bold pr-3">
                                        Package
                                      </span>
                                      {this.state.currentHistory.items.length >
                                        1 && (
                                        <span className="font-weight-bold pr-3">
                                          {index + 1} -
                                        </span>
                                      )}
                                      <span className="pull-right pr-3">
                                        Weight: {item.weight} Kg,
                                      </span>
                                      <span className="pull-right">
                                        Value: &#8358;
                                        {utils.numberFormatter(
                                          Number(item.value),
                                          2
                                        )}
                                      </span>
                                    </div>

                                    <div className="d-block d-md-none">
                                      <span className="font-weight-bold pr-3">
                                        Package
                                      </span>
                                      {this.state.currentHistory.items.length >
                                        1 && (
                                        <span className="font-weight-bold pr-3">
                                          {index + 1} -
                                        </span>
                                      )}
                                      <span className="pull-right pr-3">
                                        W: {item.weight} Kg,
                                      </span>
                                      <span className="pull-right">
                                        V: &#8358;
                                        {utils.numberFormatter(
                                          Number(item.value),
                                          2
                                        )}
                                      </span>
                                    </div>
                                    {this.loadItemConfirmationTable(
                                      item.itemUnits
                                    )}
                                  </>
                                );
                              }
                            )}

                            {currentHistory.shipmentDimension && (
                              <>
                                <div className="border-bottom border-light pb-3 mb-3" />
                                <Row>
                                  <Col className="col-12 col-md-4">
                                    Shipment Dimension:
                                  </Col>
                                  <Col>
                                    <div className="font-weight-bold">
                                      {currentHistory.shipmentDimension[0]} X{" "}
                                      {currentHistory.shipmentDimension[1]} X{" "}
                                      {currentHistory.shipmentDimension[2]}
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {renderFormPanel({
                        title: "Others",
                        content: (
                          <>
                            <Row>
                              <Col className="col-12 col-sm-6 mb-2">
                                <div className="text-muted">Insurance Type</div>
                                <div className="font-weight-bold h2">
                                  {currentHistory.insurance}
                                </div>
                              </Col>
                              <Col className="col-12 col-sm-6 mb-2">
                                <div className="text-muted">
                                  Pickup Required
                                </div>
                                <div className="font-weight-bold h2">
                                  {currentHistory.pickup ? "Yes" : "No"}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="col-12 col-sm-6 mb-2">
                                <div className="text-muted">
                                  Delivery Signature Required
                                </div>
                                <div className="font-weight-bold h2">
                                  {currentHistory.signatureRequired
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </Col>
                            </Row>
                            {currentHistory.preferredPickupDateDesc && (
                              <Row>
                                <Col className="offset-sm-6 mb-2">
                                  <div className="text-muted">
                                    Preferred Pickup Date
                                  </div>
                                  <div className="font-weight-bold h2">
                                    {currentHistory.preferredPickupDateDesc}
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {renderFormPanel({
                        title: "Payment",
                        content: (
                          <>
                            {parseFloat(currentHistory.billableWeight) >
                              parseFloat(currentHistory.grossWeight) && (
                              <>
                                <Row>
                                  <Col className="mb-2">
                                    <div className="text-muted">
                                      Shipment Actual Weight
                                    </div>
                                    <div className="font-weight-bold h2">
                                      {currentHistory.grossWeight} Kg
                                    </div>
                                  </Col>
                                  <Col className="mb-2">
                                    <div className="text-muted">
                                      Shipment Dimensional Weight
                                    </div>
                                    <div className="font-weight-bold h2">
                                      {currentHistory.billableWeight} Kg
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mb-2">
                                    <div className="text-primary font-weight-bold">
                                      Billable Weight
                                    </div>
                                    <div className="font-weight-bold h2">
                                      {currentHistory.billableWeight} Kg
                                    </div>
                                  </Col>
                                  <Col className="mb-2"></Col>
                                </Row>
                              </>
                            )}
                            {parseFloat(currentHistory.billableWeight) ===
                              parseFloat(currentHistory.grossWeight) && (
                              <>
                                <Row>
                                  <Col className="mb-2"></Col>
                                  <Col className="mb-2">
                                    <div className="text-primary font-weight-bold">
                                      Billable Weight
                                    </div>
                                    <div className="font-weight-bold h2">
                                      {currentHistory.billableWeight} Kg
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            )}

                            <hr className="my-2" />
                            <Row>
                              <Col className="col-6 col-md-4 mb-2">
                                <div className="text-muted">Shipping Rate</div>
                                <div className="font-weight-bold h2">
                                  &#8358;{" "}
                                  {utils.numberFormatter(
                                    Number(currentHistory.shippingRate),
                                    2
                                  )}
                                </div>
                              </Col>
                              <Col className="col-6 col-md-4 mb-2">
                                <div className="text-muted">Insurance Fee</div>
                                <div className="font-weight-bold h2">
                                  {(!currentHistory.insuranceFee ||
                                    currentHistory.insuranceFee == 0) && (
                                    <>--</>
                                  )}
                                  {currentHistory.insuranceFee &&
                                    currentHistory.insuranceFee != 0 && (
                                      <>
                                        &#8358;{" "}
                                        {utils.numberFormatter(
                                          Number(currentHistory.insuranceFee),
                                          2
                                        )}
                                      </>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-6 col-md-4 mb-2">
                                <div className="text-muted">Signature Fee</div>
                                <div className="font-weight-bold h2">
                                  {!currentHistory.signatureServiceFee && (
                                    <>--</>
                                  )}
                                  {currentHistory.signatureServiceFee && (
                                    <>
                                      &#8358;{" "}
                                      {utils.numberFormatter(
                                        Number(
                                          currentHistory.signatureServiceFee
                                        ),
                                        2
                                      )}
                                    </>
                                  )}
                                </div>
                              </Col>
                              <Col className="d-block d-md-none col-6 mb-2">
                                <div className="text-muted">
                                  Shipping Materials
                                </div>
                                <div className="font-weight-bold h2">
                                  {(!currentHistory.shippingBoxFee ||
                                    Number(currentHistory.shippingBoxFee) ===
                                      0) && <>--</>}
                                  {currentHistory.shippingBoxFee &&
                                    Number(currentHistory.shippingBoxFee) >
                                      0 && (
                                      <>
                                        &#8358;{" "}
                                        {utils.numberFormatter(
                                          Number(currentHistory.shippingBoxFee),
                                          2
                                        )}
                                      </>
                                    )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="d-none d-md-block col-md-4 mb-2">
                                <div className="text-muted">
                                  Shipping Materials
                                </div>
                                <div className="font-weight-bold h2">
                                  {(!currentHistory.shippingBoxFee ||
                                    Number(currentHistory.shippingBoxFee) ===
                                      0) && <>--</>}
                                  {currentHistory.shippingBoxFee &&
                                    Number(currentHistory.shippingBoxFee) >
                                      0 && (
                                      <>
                                        &#8358;{" "}
                                        {utils.numberFormatter(
                                          Number(currentHistory.shippingBoxFee),
                                          2
                                        )}
                                      </>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-6 col-md-4 mb-2">
                                <div className="text-muted">Discount</div>
                                <div className="font-weight-bold h2">
                                  {(!currentHistory.discountAmount ||
                                    Number(currentHistory.discountAmount) ===
                                      0) && <>--</>}
                                  {currentHistory.discountAmount &&
                                    Number(currentHistory.discountAmount) >
                                      0 && (
                                      <>
                                        &#8358;{" "}
                                        {utils.numberFormatter(
                                          Number(currentHistory.discountAmount),
                                          2
                                        )}
                                      </>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-6 col-md-4 mb-2">
                                <div
                                  className={`${
                                    currentHistory.additionalDocumentsFee &&
                                    Number(
                                      currentHistory.additionalDocumentsFee
                                    ) !== 0
                                      ? ""
                                      : "d-none"
                                  } no-gutter`}
                                >
                                  <div className="text-muted">
                                    Food Clearance Document(s)
                                  </div>
                                  <div className="font-weight-bold h2">
                                    &#8358;{" "}
                                    {utils.numberFormatter(
                                      Number(
                                        currentHistory.additionalDocumentsFee
                                      ),
                                      2
                                    )}
                                  </div>
                                </div>
                              </Col>
                              {currentHistory.additionalPayments.map(
                                (payments) => {
                                  return (
                                    <Col className="col-6 col-md-4 mb-2">
                                      <div className="text-muted">
                                        {payments.key}
                                      </div>
                                      <div className="font-weight-bold h2">
                                        &#8358;{" "}
                                        {utils.numberFormatter(
                                          Number(payments.value),
                                          2
                                        )}
                                      </div>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                            <Row>
                              <Col className="col-6 col-md-4 mb-2">
                                <div className="text-dark text-underline font-weight-bold mb-2 ">
                                  Total Payment Amount
                                </div>
                                <span className="font-weight-bold h2 p-2 bg-dark text-white">
                                  &#8358;{" "}
                                  {utils.numberFormatter(
                                    Number(currentHistory.paymentAmount),
                                    2
                                  )}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="my-2">
                                <div className="text-muted">Status</div>
                                <div className="font-weight-bold text-uppercase">
                                  {currentHistory.status}
                                </div>
                                <br />
                                {currentHistory.status.toUpperCase() ===
                                  "AWAITING_PAYMENT_CONFIRMATION" && (
                                  <>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => {
                                        this.setState({
                                          moveToCompleteBooking: true,
                                          completeBookingShipmentId:
                                            currentHistory.id,
                                        });
                                      }}
                                    >
                                      Complete Booking
                                    </Button>
                                    <Button
                                      outline
                                      color="danger"
                                      size="sm"
                                      onClick={async () => {
                                        await shipmentService.cancelBooking(
                                          currentHistory.id
                                        );
                                        // const incompleteBookings =
                                        //   this.state.incompleteBookings;
                                        // incompleteBookings.splice(index, 1);
                                        // this.setState({ incompleteBookings });
                                      }}
                                    >
                                      Ignore
                                    </Button>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </>
                        ),
                        bgColor: "bg-white",
                      })}
                    </Col>
                  </Row>
                </>
              )}
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("historyDetailModal")}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  };

  loadItemConfirmationTable(items) {
    return (
      <Card className="mb-0">
        <ToolkitProvider
          data={items}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
              classes: "d-none d-md-block",
              headerClasses: "d-none d-md-block",
            },
            {
              dataField: "description",
              text: "Desc",
              sort: true,
            },
            {
              dataField: "categoryName",
              text: "Category",
              sort: true,
              headerClasses: "d-none d-lg-block ",
              classes: (cell, row, rowIndex, colIndex) => {
                return `text-wrap d-none d-lg-block`;
              },
            },
            {
              dataField: "quantity",
              text: "Qty",
              sort: true,
            },
            {
              dataField: "value",
              text: <div className="text-right">Unit Price (&#8358;) </div>,
              sort: true,
              formatter: (cell, row) => {
                return (
                  <div className="text-right">
                    {utils.numberFormatter(Number(row.value), 2)}
                  </div>
                );
              },
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                size="sm"
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                // pagination={pagination}
                bordered={false}
                id="react-bs-table"
                striped
                hover
                condensed
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  loadTableContextMenu(toggleModal, row) {
    let status = row.status.toUpperCase();
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-primary h-25 mx-0"
          color=""
          role="button"
          size="sm"
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-arrow pt-0 overflow-hidden "
          right
        >
          <div className="px-3 py-2 mb-1 bg-gradient-primary">
            <h6 className="text-sm text-white m-0">{row.trackingNumber}</h6>
          </div>
          <DropdownItem
            onClick={() => {
              this.setState({ fetchingDetail: true, currentHistory: null });
              this.loadShipmentDetail(row);
              this.toggleModal("historyDetailModal");
            }}
            className="text-default"
          >
            <i className="fas fa-microscope text-primary" />
            Detail
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              this.loadTrackingDetail(row);
              this.toggleModal("trackingModal");
            }}
            className="text-default"
          >
            <i className="ni ni-pin-3 text-primary" />
            Track Shipment
          </DropdownItem>
          {this.isAdminRole() &&
            (status === "AWAITING_PAYMENT_CONFIRMATION" ||
              status === "CANCELED" ||
              status === "VOIDED") && (
              <DropdownItem
                onClick={() => {
                  this.editShipmentFunction(row.id);
                }}
                className="text-default"
              >
                <i className="fas fa-microscope text-success" />
                Edit
              </DropdownItem>
            )}
          <DropdownItem divider></DropdownItem>

          {this.isAdminRole() &&
            (status === "BOOKED" ||
              status === "COURIER_ASSIGNED" ||
              status === "DELIVERED") && (
              <>
                <DropdownItem
                  onClick={async () => {
                    try {
                      let data =
                        await fileDownloadService.getWaybillDownloadToken(
                          row.trackingNumber
                        );
                      this.downloadFile(data.message);
                    } catch (ex) {
                      if (ex.response) {
                        toast.error(ex.response.data.message);
                      }
                    }
                  }}
                  className="text-default"
                >
                  <i className="fas fa-download text-primary" />
                  Download Waybill
                </DropdownItem>
                <DropdownItem
                  onClick={async () => {
                    try {
                      let data =
                        await fileDownloadService.getInvoiceDownloadToken(
                          row.trackingNumber
                        );
                      this.downloadFile(data.message);
                    } catch (ex) {
                      if (ex.response) {
                        toast.error(ex.response.data.message);
                      }
                    }
                  }}
                  className="text-default"
                >
                  <i className="far fa-file-alt text-primary" />
                  Download INVOICE
                </DropdownItem>
                <DropdownItem divider></DropdownItem>
              </>
            )}

          {this.isAdminRole() &&
            (status === "BOOKED" ||
              status === "COURIER_ASSIGNED" ||
              status === "PENDING_ISSUE") && (
              <>
                <DropdownItem
                  onClick={() => {
                    this.setState({ voidShipmentDetail: row });
                    this.toggleModal("voidShipmentModal");
                  }}
                  className="text-default"
                >
                  <i className="fas fa-close text-danger" /> Void Shipment
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.setState({
                      voidShipmentDetail: row,
                      isVoidAndEditShipment: true,
                    });
                    this.toggleModal("voidShipmentModal");
                  }}
                  className="text-default"
                >
                  <i className="fas fa-close text-success" /> Void & Edit
                  Shipment
                </DropdownItem>
              </>
            )}
          {this.isAdminRole() &&
            (status === "BOOKED" || status === "COURIER_ASSIGNED") && (
              <>
                <DropdownItem divider></DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.setState({
                      uploadClearanceDocumentShipmentDetail: row,
                    });
                    this.toggleModal("uploadClearanceDocumentModal");
                  }}
                  className="text-default"
                >
                  <i className="fas fa-upload text-success" /> Upload Clearance
                  Document
                </DropdownItem>
              </>
            )}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  downloadFile(token) {
    const downloadUrl = fileDownloadService.getFileDownloadUrl(token);
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = downloadUrl;
    link.setAttribute("type", "hidden");
    link.click();
  }
  async loadShipmentDetail(requestDetail) {
    this.setState({ fetchingDetail: true, currentHistory: null });
    const detail = await shipmentService.fetchShipmentDetail(requestDetail.id);
    detail.clearanceDocuments = requestDetail.clearanceDocuments;
    this.setState({ fetchingDetail: false, currentHistory: detail });
  }

  trackingModal = () => {
    const trackingDetail = this.state.trackingDetail;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.trackingModal}
        toggle={() => this.toggleModal("trackingModal")}
        backdrop="static"
        size="lg"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            Tracking Information
          </h6>

          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("trackingModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {this.state.fetchingTrackingInfo
            ? showLoadingRecordsMessage("Loading... Please wait.")
            : trackingDetail && (
                <>
                  <div className="text-right">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={async () => {
                        this.loadShipmentDetail(trackingDetail);
                        this.toggleModal("historyDetailModal");
                        this.toggleModal("trackingModal");
                      }}
                    >
                      <i class="fas fa-exchange-alt"></i> show details
                    </Button>
                  </div>
                  <br />
                  {true &&
                    renderFieldGrouping(
                      null,
                      <div className="pl-2 py-2">
                        <div className="bg-white p-2 rounded mb-2">
                          <Row>
                            <Col className="col-5">
                              {this.deliveryText()}
                              <h5 className="text-primary">
                                {this.deliveryDay()}
                              </h5>
                            </Col>
                            <Col className="col-2">{this.deliveryIcon()}</Col>
                            <Col className="text-right">
                              Route
                              <h5 className="text-primary">
                                {trackingDetail.fromLocation}{" "}
                                <span className="fas fa-arrow-right" />{" "}
                                {trackingDetail.toLocation}
                              </h5>
                            </Col>
                          </Row>
                          <div className="text-center">
                            {this.loadTrackingNumbers(trackingDetail.courier)}
                          </div>
                        </div>
                        <Timeline
                          items={trackingDetail.trackingList}
                          format="hh:mm a"
                        />
                      </div>,
                      "bg-light"
                    )}
                </>
              )}
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("trackingModal")}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  };

  voidShipmentModal = () => {
    const voidShipmentDetail = this.state.voidShipmentDetail;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.voidShipmentModal}
        toggle={() => this.toggleModal("voidShipmentModal")}
        backdrop="static"
        size="md"
      >
        <div className="modal-header bg-danger">
          <h6 className="modal-title text-white" id="modal-title-notification">
            Void Shipment Confirmation
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("voidShipmentModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {voidShipmentDetail && (
            <>
              <div className="text-black">
                Do you want to void the shipment with details below?
              </div>
              <br /> <br />
              <Row>
                <Col className="col-6">Tracking Number: </Col>
                <Col className="col-6 font-weight-bold">
                  {voidShipmentDetail.trackingNumber}
                </Col>
              </Row>
              <br />
              <Row>
                <Col className="col-6">Sender: </Col>
                <Col className="col-6 font-weight-bold">
                  {voidShipmentDetail.sender}
                  <br />
                  <span className="text-primary font-weight-bold">
                    {voidShipmentDetail.originState}
                  </span>
                </Col>
              </Row>
              <br />
              <Row>
                <Col className="col-6">Receiver: </Col>
                <Col className="col-6 font-weight-bold">
                  {voidShipmentDetail.recipient}
                  <br />
                  <span className="text-primary font-weight-bold">
                    {voidShipmentDetail.destinationCountry}
                  </span>
                </Col>
              </Row>
            </>
          )}
        </div>
        <div className="modal-footer bg-white">
          <Button
            className="ml-auto"
            color="dark"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              this.toggleModal("voidShipmentModal");
              this.setState({ isVoidAndEditShipment: false });
            }}
          >
            Close
          </Button>
          <Button
            color="danger"
            type="button"
            onClick={async () => {
              await this.voidShipmentFunction(voidShipmentDetail.id);
            }}
            disabled={this.state.voidShipmentInProgress}
          >
            <span className={this.state.voidShipmentInProgress ? "" : "d-none"}>
              <Spinner type="border" size="sm"></Spinner> Voiding Shipment...
            </span>
            <span className={this.state.voidShipmentInProgress ? "d-none" : ""}>
              Yes, Void Shipment
            </span>
          </Button>
        </div>
      </Modal>
    );
  };

  async voidShipmentFunction(shipmentId) {
    try {
      const resp = await shipmentService.voidShipment(shipmentId);
      toast.success(resp.message);

      let { history } = this.state;
      history = history.map((h) => {
        if (h.id === shipmentId) {
          h.status = "Voided";
        }
        return h;
      });
      this.setState(
        {
          history: [...history],
          voidShipmentInProgress: false,
          voidShipmentModal: false,
        },
        () => {
          this.timer = setTimeout(() => {
            if (this.state.isVoidAndEditShipment) {
              this.editShipmentFunction(shipmentId);
              this.setState({ isVoidAndEditShipment: false });
            }
          }, 500);
        }
      );
    } catch (ex) {
      this.setState(
        {
          voidShipmentModal: false,
        },
        () => {
          this.timer = setTimeout(() => {
            if (this.state.isVoidAndEditShipment) {
              this.editShipmentFunction(shipmentId);
              this.setState({ isVoidAndEditShipment: false });
            }
          }, 500);
        }
      );
    }
  }

  editShipmentFunction(shipmentId) {
    this.setState({ editShipment: true, editShipmentId: shipmentId });
  }

  uploadClearanceDocumentModal = () => {
    const uploadClearanceDocumentShipmentDetail =
      this.state.uploadClearanceDocumentShipmentDetail;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.uploadClearanceDocumentModal}
        toggle={() => this.toggleModal("uploadClearanceDocumentModal")}
        backdrop="static"
        size="md"
      >
        <div className="modal-header bg-success">
          <h6 className="modal-title text-white" id="modal-title-notification">
            Submit Clearance Document
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("uploadClearanceDocumentModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {uploadClearanceDocumentShipmentDetail && (
            <>
              <Row>
                <Col className="col-6">Tracking Number: </Col>
                <Col className="col-6 font-weight-bold">
                  {uploadClearanceDocumentShipmentDetail.trackingNumber}
                  <br />
                  <span className="text-primary font-weight-bold">
                    {uploadClearanceDocumentShipmentDetail.courier}
                  </span>
                </Col>
              </Row>
              <br />
              <Row>
                <Col className="col-6">Sender: </Col>
                <Col className="col-6 font-weight-bold">
                  {uploadClearanceDocumentShipmentDetail.sender}
                  <br />
                  <span className="text-primary font-weight-bold">
                    {uploadClearanceDocumentShipmentDetail.originState}
                  </span>
                </Col>
              </Row>
              <br />
              <Row>
                <Col className="col-6">Receiver: </Col>
                <Col className="col-6 font-weight-bold">
                  {uploadClearanceDocumentShipmentDetail.recipient}
                  <br />
                  <span className="text-primary font-weight-bold">
                    {uploadClearanceDocumentShipmentDetail.destinationCountry}
                  </span>
                </Col>
              </Row>
              <br />
              <div className="text-danger text-left font-weight-bold">
                ONLY PDF FILE SUPPORTED
              </div>
              <Row>
                <Col className="col-6">
                  <label className="btn btn-primary btn-sm">
                    <span
                      className={this.state.processingImage ? "" : "d-none"}
                    >
                      <Spinner type="border" size="sm"></Spinner>
                    </span>
                    &nbsp; Select Clearance Document
                    <input
                      type="file"
                      name="file"
                      className="d-none"
                      onChange={(event) => {
                        this.uploadLogoChangeHandler(event);
                      }}
                    />
                  </label>
                </Col>
                <Col className="col-6 text-wrap text-break font-weight-bold">
                  {this.state.uploadClearanceDocumentFilename}
                </Col>
              </Row>
              <br />
              <Row>
                <Col className="col-12 font-weight-bold">
                  {this.renderSelect2({
                    mandatory: true,
                    type: "text",
                    name: "shippingDocumentTypeList",
                    placeholder: "Select Clearance Document Type",
                    icon: "fas fa-address-book",
                    options: this.state.shippingDocumentTypeList,
                    onchange: async (selectedItem) => {
                      this.setState({
                        uploadClearanceDocumentType: selectedItem.value,
                      });
                    },
                  })}
                </Col>
              </Row>
            </>
          )}
        </div>
        <div className="modal-footer bg-white">
          <Button
            className="ml-auto"
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("uploadClearanceDocumentModal")}
          >
            Close
          </Button>
          <Button
            color="success"
            type="button"
            onClick={async () => {
              await this.submitClearanceDocument();
            }}
            disabled={
              this.state.uploadClearanceDocumentInProgress ||
              !this.state.uploadClearanceDocumentFormData ||
              !this.state.uploadClearanceDocumentType
            }
          >
            <span
              className={
                this.state.uploadClearanceDocumentInProgress ? "" : "d-none"
              }
            >
              <Spinner type="border" size="sm"></Spinner> Submitting Clearance
              Document...
            </span>
            <span
              className={
                this.state.uploadClearanceDocumentInProgress ? "d-none" : ""
              }
            >
              Submit Clearance Document
            </span>
          </Button>
        </div>
      </Modal>
    );
  };

  async uploadLogoChangeHandler(event) {
    this.setState({
      uploadClearanceDocumentFormData: null,
      uploadClearanceDocumentFilename: null,
    });

    if (!event.target.files[0]) return;

    if (
      !this.isValidSize(event, this.state.fileProperties.maxAllowedFileSize)
    ) {
      toast.error(
        "Allowed file size (" +
          this.state.fileProperties.maxAllowedFileSize +
          " bytes) exceeded"
      );
      return;
    }

    if (!this.isValidMimeType(event, this.state.fileProperties.mimeTypes)) {
      toast.error("Upload file type is unsupported.");
      return;
    }

    let file = event.target.files[0];

    try {
      const formData = new FormData();
      if (file.type.includes("pdf")) {
        formData.append("file", file, file.name);
        this.setState({
          uploadClearanceDocumentFormData: formData,
          uploadClearanceDocumentFilename: file.name,
        });
      } else {
        toast.error("Selected file type is not supported.");
      }
    } catch (err) {
      console.log(err);
    }
  }

  async submitClearanceDocument() {
    try {
      this.setState({
        processingImage: true,
        uploadClearanceDocumentInProgress: true,
      });

      let resp = await shipmentService.submitClearanceDocument(
        this.state.uploadClearanceDocumentShipmentDetail.id,
        this.state.uploadClearanceDocumentType,
        this.state.uploadClearanceDocumentFormData
      );

      toast.success(resp.message);

      this.setState({
        processingImage: false,
        uploadClearanceDocumentShipmentDetail: null,
        uploadClearanceDocumentFormData: null,
        uploadClearanceDocumentInProgress: false,
        uploadClearanceDocumentModal: false,
        uploadClearanceDocumentFilename: "",
        uploadClearanceDocumentType: null,
      });
    } catch (ex) {
      this.setState({
        processingImage: false,
        uploadClearanceDocumentInProgress: false,
      });
    }
  }

  isValidMimeType(event, acceptedTypes) {
    //getting file object
    let file = event.target.files[0];
    let isValid = false;
    // compare file type find doesn't matach
    acceptedTypes.map((type) => {
      if (file.type === type) {
        isValid = true;
      }
    });
    if (isValid) {
      return true;
    }
    event.target.value = null;
    return false;
  }

  isValidSize(event, size) {
    //getting file object
    const file = event.target.files[0];

    if (file && file.size <= size) return true;

    event.target.value = null;
    return false;
  }

  deliveryText() {
    if (this.state.trackingDetail.delivered) {
      return "Date Delivered";
    } else if (this.state.trackingDetail.deliveryDay) {
      return "Estimated Delivery Date";
    }
  }

  deliveryDay() {
    if (this.state.trackingDetail.deliveryDay) {
      return this.state.trackingDetail.deliveryDay;
    }
  }

  deliveryIcon() {
    if (this.state.trackingDetail.delivered) {
      return (
        <span class="text-success">
          <i class="fas fa-check-circle fa-2xl"></i>
        </span>
      );
    } else if (this.state.trackingDetail.deliveryDay) {
      return <Spinner type="grow" className="text-primary"></Spinner>;
    }
  }

  isAdminRole() {
    return (
      auth.getCurrentUserRole() === "ROOT" ||
      auth.getCurrentUserRole() === "ADMIN" ||
      auth.getCurrentUserRole() === "MANAGER"
    );
  }
}

export default ShippingHistory;
