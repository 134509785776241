import React from "react";
import Container from "reactstrap/lib/Container";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Joi from "joi-browser";
import Button from "reactstrap/lib/Button";
import { renderFieldGrouping, renderFormPanel } from "config/config";
import {
  COUNTRY_LIST,
  ORIGIN_COUNTRIES,
  NIGERIAN_STATES,
} from "constants/constants";
import shipmentService from "services/shipmentService";
import locationService from "services/locationService";
import auth from "services/authService";
import utils from "util/CustomUtil";
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";

class CheckShippingRate extends ValidationForm {
  state = {
    data: {
      senderCountry: {
        value: "NG",
        label: "Nigeria",
      },
    },
    errors: {},
    formSubmitted: false,
    fieldIsProcessing: {},
    isLoading: false,
    senderStateList: [],
    recipientStateList: [],
    recipientCityList: [],
    shipment: {},
    showShipmentRate: false,
    showRecipientCity: false,
    senderCityList: [],
    showSenderCity: false,
    recipientCountryList: [...COUNTRY_LIST],
    copiedRates: null,
    tab: "tab-international",
    isExport: true,
  };

  schema = {
    senderCountry: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Country"),
    senderStateDomestic: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("State"),
    senderCityDomestic: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    }).label("City"),
    // senderPostalCode: Joi.string().allow("").label("Postal Code"),
    recipientCountry: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Country"),
    recipientStateDomestic: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("State"),
    recipientCityDomestic: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    }).label("City"),
    length: Joi.number().min(0).default(0).label("Length"),
    width: Joi.number().min(0).default(0).label("Width"),
    height: Joi.number().min(0).default(0).label("Height"),
    internationalPackageWeight: Joi.number()
      .min(0.1)
      .required()
      .label("Package Weight"),
    domesticPackageWeight: Joi.number()
      .min(0.1)
      .required()
      .label("Package Weight"),
  };

  internationalFormParam = [
    "senderCountry",
    "recipientCountry",
    "internationalPackageWeight",
  ];

  domesticFormParam = [
    "senderStateDomestic",
    "recipientStateDomestic",
    "recipientCityDomestic",
    "domesticPackageWeight",
  ];

  async doSubmit() {
    this.setState({ formSubmitted: true });

    try {
      let { data, isExport } = this.state;

      let shipment = {
        export: isExport,
        origin: {},
        destination: {},
        items: [
          {
            quantity: 1,
            packageDimension: [
              data.length ? data.length.replace(/\s/g, "") : data.length,
              data.width ? data.width.replace(/\s/g, "") : data.width,
              data.height ? data.height.replace(/\s/g, "") : data.height,
            ],
          },
        ],
      };

      if (isExport) {
        shipment.origin.country = data.senderCountry.value;
        shipment.destination.country = data.recipientCountry.value;
        shipment.origin.state = null;
        shipment.destination.state = null;
        shipment.destination.city = null;

        shipment.items[0].weight = data.internationalPackageWeight.replace(
          /\s/g,
          ""
        );
      } else {
        shipment.origin.country = "NG";
        shipment.destination.country = "NG";
        shipment.origin.state = "NG_" + data.senderStateDomestic.value;
        shipment.destination.state = "NG_" + data.recipientStateDomestic.value;
        shipment.destination.city = data.recipientCityDomestic.value;

        shipment.items[0].weight = data.domesticPackageWeight.replace(
          /\s/g,
          ""
        );
      }

      let rates = await shipmentService.getRate(shipment);
      let copiedRates = rates.partnerGroupName
        ? "*" + rates.partnerGroupName + " Rates*\n"
        : "*Estimated Shipping Rate*\n";

      copiedRates +=
        "*" +
        utils.numberFormatter(Number(rates.items[0].weight), 2) +
        " Kg | " +
        rates.origin.country +
        " -> " +
        rates.destination.country;
      copiedRates += "*\n";

      rates.courierRates.map((rate) => {
        // copiedRates += rate.serviceDescription.toUpperCase();
        copiedRates += "\n*[";
        copiedRates += rate.serviceDescription.toUpperCase();
        copiedRates += "]*\n";
        copiedRates += rate.deliveryTimeline;
        copiedRates += "\n₦ ";
        copiedRates += utils.numberFormatter(Number(rate.shippingRate), 2);
        copiedRates += "\n";
      });
      if (rates.shippingBoxFee !== "0.00") {
        copiedRates += "\n*****************\n\n";
        copiedRates += "*Shipping Materials/Box*\n₦ ";
        copiedRates += utils.numberFormatter(Number(rates.shippingBoxFee), 2);
        copiedRates += " Per Box\n";
      }
      if (
        rates.platformInformationList &&
        rates.platformInformationList.length > 0
      ) {
        let count = 1;
        copiedRates += "\n*****************\n";
        rates.platformInformationList.map((info) => {
          copiedRates += count++ + ". " + info.detail;
          copiedRates += "\n";
        });
      }

      this.setState({ copiedRates, shipment: rates, showShipmentRate: true });

      utils.logEvent({
        page: "Shipping Rate",
        source: "www.wirepackage.com/base/rates",
        custom: {
          origin_country: data.senderCountry
            ? data.senderCountry.label
            : "Nigeria",
          origin_state:
            shipment.destination.country == "NG"
              ? data.senderStateDomestic.label
              : null,
          recipient_country: data.recipientCountry
            ? data.recipientCountry.label
            : "Nigeria",
          recipient_state:
            shipment.destination.country == "NG"
              ? data.recipientStateDomestic.label
              : null,
          weight: shipment.items[0].weight.replace(/\s/g, ""),
          dimension:
            (data.length ? data.length.replace(/\s/g, "") : 0) +
            " x " +
            (data.width ? data.width.replace(/\s/g, "") : 0) +
            " x " +
            (data.height ? data.height.replace(/\s/g, "") : 0),
        },
      });

      //scroll up window
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    } catch (ex) {
      console.log(ex);
    }
    this.setState({ formSubmitted: false });
  }

  render() {
    let { isLoading } = this.state;

    return (
      <>
        <SimpleHeader
          name="Shipping Rates"
          parentName="Tables"
          description="Check rates for international and domestic delivereis."
        />
        <Container className="mt--6" fluid>
          {this.state.showShipmentRate
            ? this.displayRate()
            : this.captureShipmentDetails()}
        </Container>
      </>
    );
  }

  domesticRates() {
    return (
      <form
        role="form"
        onSubmit={(e) => {
          this.handleSubmit({ e, formItemNames: this.domesticFormParam });
        }}
      >
        <Row className="mb-5">
          <Col className="offset-md-1 col-md-10">
            <Row>
              <Col xs={12}>
                <div className="border rounded bg-success h2 text-white p-2">
                  Nationwide Delivery Rates
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                {renderFieldGrouping(
                  "Origin",
                  <>
                    <Row>
                      <Col sm={12} className="pt-3 mb-3">
                        {this.renderSelect2({
                          mandatory: false,
                          name: "senderStateDomestic",
                          options: [...NIGERIAN_STATES],
                          placeholder: "Select State",
                          icon: "ni ni-world-2",
                          onchange: async (selectedItem) => {
                            try {
                              const { fieldIsProcessing, data } = this.state;

                              data.senderCountry.value = "NG";
                              data.senderCountry.label = "Nigeria";
                              data.senderCityDomestic = selectedItem;
                              data.senderStateDomestic = selectedItem;
                              this.setState({
                                data,
                              });

                              fieldIsProcessing.senderCityDomestic = true;

                              this.setState({
                                fieldIsProcessing,
                              });
                              let senderCityList = [];
                              try {
                                senderCityList =
                                  await locationService.fetchLocation(
                                    "NG_" + selectedItem.value
                                  );
                              } catch (e) {
                                senderCityList = [];
                              }

                              const {
                                fieldIsProcessing: fieldIsProcessingUpdate,
                                data: datas,
                              } = this.state;
                              datas.senderCityDomestic = null;
                              datas.senderStateDomestic = selectedItem;
                              fieldIsProcessingUpdate.senderCityDomestic = false;

                              this.setState({
                                fieldIsProcessing: fieldIsProcessingUpdate,
                                senderCityList,
                                data: datas,
                              });
                            } catch (ex) {
                              console.log(ex);
                            }
                          },
                        })}
                      </Col>
                      {/* <Col sm={12}>
                        <div className="mb-3">
                          {this.renderSelect2({
                            mandatory: true,
                            name: "senderCityDomestic",
                            placeholder: "Select City",
                            icon: "fas fa-city",
                            options: this.state.senderCityList,
                          })}
                        </div>
                      </Col> */}
                    </Row>
                  </>,
                  "bg-light",
                  "bg-success"
                )}
              </Col>
              <Col xs={12} md={6} className="d-none d-md-block">
                {renderFieldGrouping(
                  "Package Weight (Kg)",
                  <>
                    <Row>
                      <Col className="pt-3">
                        {this.renderStyledInput({
                          mandatory: true,
                          type: "text",
                          name: "domesticPackageWeight",
                          placeholder: "Package Weight",
                          icon: "fas fa-weight",
                        })}
                      </Col>
                    </Row>
                  </>,
                  "bg-light",
                  "bg-success"
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                {renderFieldGrouping(
                  "Destination",
                  <>
                    <Row>
                      <Col sm={12} className="pt-3 mb-3">
                        {this.renderSelect2({
                          mandatory: true,
                          name: "recipientStateDomestic",
                          placeholder: "Select State",
                          icon: "ni ni-world-2",
                          options: [...NIGERIAN_STATES],

                          onchange: async (selectedItem) => {
                            try {
                              const { fieldIsProcessing, data } = this.state;

                              data.recipientCityDomestic = selectedItem;
                              data.recipientStateDomestic = selectedItem;
                              this.setState({
                                data,
                              });

                              this.setState({
                                fieldIsProcessing,
                              });

                              let recipientCityList = [];
                              try {
                                recipientCityList =
                                  await locationService.fetchLocation(
                                    "NG_" + selectedItem.value
                                  );
                              } catch (e) {
                                recipientCityList = [];
                              }

                              const {
                                fieldIsProcessing: fieldIsProcessingUpdate,
                                data: datas,
                              } = this.state;
                              datas.recipientCityDomestic = null;
                              datas.recipientStateDomestic = selectedItem;
                              fieldIsProcessingUpdate.recipientCityDomestic = false;

                              this.setState({
                                fieldIsProcessing: fieldIsProcessingUpdate,
                                recipientCityList,
                                data: datas,
                              });
                            } catch (ex) {
                              console.log(ex);
                            }
                          },
                        })}
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          {this.renderSelect2({
                            mandatory: true,
                            type: "text",
                            name: "recipientCityDomestic",
                            placeholder: "Select City",
                            icon: "fas fa-city",
                            options: this.state.recipientCityList,
                          })}
                        </div>
                      </Col>
                    </Row>
                  </>,
                  "bg-light",
                  "bg-success"
                )}
              </Col>
              <Col xs={12} md={6} className="d-block d-md-none">
                {renderFieldGrouping(
                  "Package Weight (Kg)",
                  <>
                    <Row>
                      <Col className="pt-3">
                        {this.renderStyledInput({
                          mandatory: true,
                          type: "text",
                          name: "domesticPackageWeight",
                          placeholder: "Package Weight",
                          icon: "fas fa-weight",
                        })}
                      </Col>
                    </Row>
                  </>,
                  "bg-light",
                  "bg-success"
                )}
              </Col>
              <Col xs={12} md={6} className="d-flex align-items-center">
                {/* {renderFieldGrouping(
                  "(Optional) Shipment Dimension if you know it.",
                  <>
                    <Row className="no-gutter">
                      <Col className="col-4">
                        {this.renderThresholdInput(
                          false,
                          "length",
                          "Length (CM)",
                          "text",
                          "Optional"
                        )}
                      </Col>
                      <Col className="col-4">
                        {this.renderThresholdInput(
                          false,
                          "width",
                          "Width (CM)",
                          "text",
                          "Optional"
                        )}
                      </Col>
                      <Col className="col-4">
                        {this.renderThresholdInput(
                          false,
                          "height",
                          "Height (CM)",
                          "text",
                          "Optional"
                        )}
                      </Col>
                    </Row>
                  </>,
                  "bg-light",
                  "bg-success"
                )} */}
                {this.renderButton(
                  "Get Rate",
                  "btn-success btn-lg btn-block text-lg",
                  "Get Rate",
                  this.domesticFormParam
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    );
  }

  internationalRates() {
    return (
      <form
        role="form"
        onSubmit={(e) => {
          this.handleSubmit({ e, formItemNames: this.internationalFormParam });
        }}
      >
        <Row className="mb-5">
          <Col className="offset-md-1 col-md-10">
            <div className="border rounded bg-primary h2 text-white p-2">
              International Delivery Rates
            </div>
            <Row>
              <Col xs={12} md={6}>
                {renderFieldGrouping(
                  "Origin",
                  <>
                    <Row>
                      <Col sm={12} className="pt-3 mb-3">
                        {this.renderSelect2({
                          mandatory: false,
                          name: "senderCountry",
                          options: [...ORIGIN_COUNTRIES],
                          placeholder: "Select Country",
                          icon: "fas fa-flag",
                          onchange: async (selectedItem) => {
                            try {
                              const data = this.state.data;
                              let recipientCountryList = [];
                              if (selectedItem.value === "NG") {
                                recipientCountryList = [...COUNTRY_LIST];
                              } else {
                                recipientCountryList = [
                                  {
                                    value: "NG",
                                    label: "Nigeria",
                                  },
                                ];
                              }
                              data.senderCountry = {
                                value: selectedItem.value,
                                label: selectedItem.label,
                              };
                              data.recipientCountry = null;
                              this.setState({
                                data,
                                recipientCountryList,
                              });
                            } catch (ex) {
                              console.log(ex);
                            }
                          },
                        })}
                      </Col>
                    </Row>
                  </>,
                  "bg-light"
                )}
              </Col>
              <Col>
                {renderFieldGrouping(
                  "Destination",
                  <>
                    <Row>
                      <Col sm={12} className="pt-3 mb-3">
                        {this.renderSelect2({
                          name: "recipientCountry",
                          options: COUNTRY_LIST.filter((c) => c.value !== "NG"),
                          placeholder: "Select Country",
                          icon: "fas fa-flag",
                        })}
                      </Col>
                    </Row>
                  </>,
                  "bg-light"
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                {renderFieldGrouping(
                  "Package Weight (Kg)",
                  <>
                    <Row>
                      <Col className="pt-3">
                        {this.renderStyledInput({
                          mandatory: true,
                          type: "text",
                          name: "internationalPackageWeight",
                          placeholder: "Package Weight",
                          icon: "fas fa-weight",
                        })}
                      </Col>
                    </Row>
                  </>,
                  "bg-light"
                )}
              </Col>
              <Col className="d-flex align-items-center">
                {/* {renderFieldGrouping(
                  "(Optional) Shipment Dimension if you know it.",
                  <>
                    <Row className="no-gutter">
                      <Col className="col-4">
                        {this.renderThresholdInput(
                          false,
                          "length",
                          "Length (CM)",
                          "text",
                          "Optional"
                        )}
                      </Col>
                      <Col className="col-4">
                        {this.renderThresholdInput(
                          false,
                          "width",
                          "Width (CM)",
                          "text",
                          "Optional"
                        )}
                      </Col>
                      <Col className="col-4">
                        {this.renderThresholdInput(
                          false,
                          "height",
                          "Height (CM)",
                          "text",
                          "Optional"
                        )}
                      </Col>
                    </Row>
                  </>,
                  "bg-light"
                )} */}
                {this.renderButton(
                  "Get Rate",
                  "btn-primary btn-lg btn-block text-lg",
                  "Get Rate",
                  this.internationalFormParam
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    );
  }

  captureShipmentDetails() {
    let tab = this.state.tab;
    return (
      <Row>
        <div className="col">
          <div className="nav-wrapper py-2 reflection-bg-secondary">
            <Nav className="nav-fill flex-row flex-md-row bg-white" tabs>
              <NavItem>
                <NavLink
                  className={
                    "font-weight-bold mb-sm-3 mb-md-0 " +
                    (tab === "tab-international"
                      ? "active text-white bg-primary text-underline"
                      : "")
                  }
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ tab: "tab-international", isExport: true });
                  }}
                >
                  Export Rates
                </NavLink>
              </NavItem>
              <NavItem className="bg-white">
                <NavLink
                  className={
                    "font-weight-bold mb-sm-3 mb-md-0 " +
                    (tab === "tab-domestic"
                      ? "active text-white bg-success font-weight-bolder text-underline"
                      : "")
                  }
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ tab: "tab-domestic", isExport: false });
                  }}
                >
                  Domestic Rates
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <Card className="shadow min-vh-50">
            <CardBody className="min-vh-50 bg-secondary border">
              <TabContent id="myTabContent" activeTab={tab}>
                <TabPane tabId="tab-international" role="tabpanel">
                  {tab === "tab-international" && this.internationalRates()}
                </TabPane>
                <TabPane tabId="tab-domestic" role="tabpanel">
                  {tab === "tab-domestic" && this.domesticRates()}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </Row>
    );
  }

  loadCopyButton() {
    return (
      <div className="text-center">
        <CopyToClipboard
          text={this.state.copiedRates}
          onCopy={() =>
            toast.success("Rates Copied", {
              toastId: "ratesCopiedMsg",
            })
          }
        >
          <Button
            color="primary"
            type="button"
            // onClick={() => this.toggleModal("regenerateTokenModal")}
            size="sm"
          >
            Copy Rates
          </Button>
        </CopyToClipboard>
      </div>
    );
  }

  loadRateOptions() {
    let opts = [];

    if (this.state.shipment.courierRates) {
      this.state.shipment.courierRates.map((rate) => {
        opts[opts.length] = (
          <div
            key={`${"rates" - opts.length}`}
            className={`ml-0 ${
              opts.length !== this.state.shipment.courierRates.length - 1
                ? "mb-3"
                : ""
            }`}
          >
            <div key={`insurdiv-${rate.courier}`} className="text-left">
              <div
                key={`insurdiv1-${rate.courier}`}
                className="border-bottom border-light"
              >
                <Row>
                  <Col className="col-12">
                    <span className="badge badge-primary w-100">
                      <span className="h2 badge-primary">
                        {rate.serviceDescription}
                      </span>
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="d-none d-md-block">
                <Row>
                  <Col className="text-left col-6">
                    <div className="display-4 mb-0 text-lowercase">
                      {rate.deliveryTimeline}
                    </div>
                    {rate.logoURL && (
                      <div
                        className="float-left"
                        style={{ height: 25, width: 80 }}
                      >
                        <img
                          src={rate.logoURL}
                          className="img-thumbnail bg-white"
                          alt={rate.courier}
                          style={{
                            objectFit: "contain",
                            padding: 0,
                            border: 0,
                          }}
                        />
                      </div>
                    )}
                  </Col>

                  <Col className="text-right col-6">
                    <div className="display-4">
                      &#8358;{" "}
                      {utils.numberFormatter(Number(rate.shippingRate), 2)}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="d-block d-md-none d-lg-none">
                <Row>
                  <Col className="text-left col-8">
                    <div className="display-4 mb-0 text-lowercase">
                      {rate.deliveryTimeline}
                    </div>
                  </Col>
                  <Col className="text-right col-4">
                    {rate.logoURL && (
                      <div
                        className="float-left"
                        style={{ height: 25, width: 80 }}
                      >
                        <img
                          src={rate.logoURL}
                          className="img-thumbnail bg-white"
                          alt={rate.courier}
                          style={{
                            objectFit: "contain",
                            padding: 0,
                            border: 0,
                          }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="display-4">
                  &#8358; {utils.numberFormatter(Number(rate.shippingRate), 2)}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    return opts;
  }

  shippingRateSubjectHeader() {
    if (this.state.shipment.partnerGroupName) {
      return (
        <h1 className="">
          <span className="wirepackage-text-bright-gold bg-dark px-2 py-1 rounded">
            <i class="fas fa-medal fa-flip-vertical"></i> &nbsp;
            {this.state.shipment.partnerGroupName} Rate (
            {this.state.shipment.destination.country == "NG"
              ? "DOMESTIC"
              : "EXPORT"}
            )
          </span>
        </h1>
      );
    }

    return (
      <h1 className="font-weight-normal text-underline">
        Estimated Shipping Rate (
        {this.state.shipment.destination.country == "NG"
          ? "DOMESTIC"
          : "EXPORT"}
        )
      </h1>
    );
  }

  determineRoute() {
    if (this.state.shipment.destination.country == "NG") {
      return (
        <>
          {this.state.shipment.origin.state.replace("NG_", "")}{" "}
          <span className="fas fa-arrow-right"></span>{" "}
          <span className="display-4">
            {this.state.shipment.destination.state.replace("NG_", "")}
          </span>
        </>
      );
    } else {
      return (
        <>
          {this.state.shipment.origin.country}{" "}
          <span className="fas fa-arrow-right"></span>{" "}
          <span className="display-4">
            {this.state.shipment.destination.country}
          </span>
        </>
      );
    }
  }
  displayRate() {
    return (
      <>
        <Row className="justify-content-center">
          <Col className="col-12 col-md-9 col-lg-8 py-3 bg-white rounded shadow-lg border border-light">
            <div className="text-center mb-1">
              {this.shippingRateSubjectHeader()}

              <Row className="mb-1 p-2">
                <Col className="">
                  <span className="display-4">
                    {utils.numberFormatter(
                      Number(this.state.shipment.items[0].weight),
                      2
                    )}{" "}
                  </span>
                  Kg | {this.determineRoute()}
                </Col>
              </Row>
            </div>
            {this.isAdminRole() && this.loadCopyButton()}
            <br />
            {this.state.shipment.platformInformationList &&
              this.state.shipment.platformInformationList.length > 0 &&
              this.loadServiceSelectionInformation()}
            {this.loadRateOptions()}
            <br />
            <div className="p-3 mb-4 bg-lighter fade show border border-light">
              <div className="text-center">
                <h2>
                  <u>Volumetric Weight Information</u>
                </h2>
              </div>
              If the <b>volumetric weight</b> of your package is higher than its{" "}
              <b>actual weight</b>, then your shipping rate will be based on the{" "}
              <b>volumetric weight</b> of your package.
              <br />
              <br />
              <ul>
                <li>
                  <b>Volumetric Weight</b> = L x W x H (all in centimeters) /
                  5000
                </li>
                <li>
                  <b>Actual Weight</b> = the weight of your package on the scale
                </li>
              </ul>
            </div>
            <div className="border-top border-light text-center text-uppercase text-muted mt-4 pt-3 h2">
              Pick Up: 24 - 48 Hours
            </div>
          </Col>
        </Row>

        <Row className="mb-3 mt-4">
          <Col className="offset-md-3 col-md-3">
            <Button
              type="text"
              color="primary"
              size="md"
              block
              onClick={() => this.setState({ showShipmentRate: false })}
            >
              Back
            </Button>
          </Col>
          <Col className="col-md-3">
            <Button
              type="text"
              color="primary"
              size="md"
              block
              onClick={() => this.props.history.push("/base/shipment/book")}
              disabled={this.state.accountId < 1}
            >
              Book Shipment
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  loadServiceSelectionInformation() {
    let platformInformationList = null;

    if (
      !this.state.shipment.platformInformationList ||
      this.state.shipment.platformInformationList.length == 0
    ) {
      return platformInformationList;
    }

    return (
      <div className="p-3 mb-4 bg-primary fade show border border-light text-white font-weight-bold">
        {this.state.shipment.platformInformationList.map((info) => {
          return (
            <div className="mb-2">
              <i class="fas fa-info-circle"></i> {info.detail}
            </div>
          );
        })}
      </div>
    );
  }

  isAdminRole() {
    return (
      auth.getCurrentUserRole() === "ROOT" ||
      auth.getCurrentUserRole() === "ADMIN" ||
      auth.getCurrentUserRole() === "MANAGER"
    );
  }
}

export default CheckShippingRate;
