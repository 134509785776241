import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
// import { PaystackConsumer } from "react-paystack";
import {
  pagination,
  renderFormPanel,
  showLoadingRecordsMessage,
  toastOptions,
} from "config/config";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import Container from "reactstrap/lib/Container";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ValidationForm from "../../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";

import Joi from "joi-browser";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import Button from "reactstrap/lib/Button";
import Modal from "reactstrap/lib/Modal";
import {
  COUNTRY_LIST,
  GLOBAL_PROPERTY,
  NIGERIAN_STATES,
} from "constants/constants";
import shipmentService from "services/shipmentService";
import locationService from "services/locationService";
import shipmentAdditionalPaymentService from "services/shipmentAdditionalPaymentService";
import walletService from "services/walletService";
import Alert from "reactstrap/lib/Alert";
import auth from "services/authService";
import utils from "util/CustomUtil";
import Spinner from "reactstrap/lib/Spinner";
import { toast } from "react-toastify";
import ReactDatetime from "react-datetime";
import FormGroup from "reactstrap/lib/FormGroup";
import { closePaymentModal, FlutterWaveButton } from "flutterwave-react-v3";
import ErrorBoundary from "ErrorBoundary";

const paystackConfig = {
  public_key: "FLWPUBK-85c92ca0e03220367ec65735f62eaee8-X",
};

const dateFormat = "YYYY-MM-DD";

class CompleteBooking extends ValidationForm {
  state = {
    data: { additionalPaymentTypeList: [], additionalPaymentAmount: null },
    errors: {},
    shipment: null,
    formSubmitted: false,
    fieldIsProcessing: {},
    payWithWalletModal: false,
    submitShipmentInProgress: false,
    fetchingWalletBalance: false,
    fetchedWalletBalance: false,
    fetchedWalletBalanceType: null,
    wallet: null,
    payWithWalletBalanceInProgress: false,
    paymentCompleted: false,
    preferredPickupDate: "",
    hidePreferredPickupDateField: true,
    showGiftCode: false,
    showDiscountInfo: false,
    applyingGiftCode: false,
    discountInfo: {},
    totalPaymentAmount: null,
    additionalPaymentTypeList: [],
    selectedAdditionalPaymentType: null,
  };

  schema = {
    giftCode: Joi.string().min(2).max(30).required().label("Code"),
    additionalPaymentAmount: Joi.number()
      .min(0.01)
      .max(100000000)
      .required()
      .label("Additional Payment Amount"),
    additionalPaymentTypeList: Joi.object({
      value: Joi.string().required().label("Value"),
      label: Joi.string().required().label("Label"),
    })
      .required()
      .label("Additional Payment List"),
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  async componentDidMount() {
    const { showDiscountInfo, discountInfo, shipmentId } = this.props;

    const shipment = await shipmentService.loadIncompleteBookingDetail(
      shipmentId
    );

    this.setState({
      shipment,
      totalPaymentAmount: shipment.paymentAmount,
      showDiscountInfo: shipment.discountModel ? true : false,
      discountInfo: shipment.discountModel || {},
    });

    if (this.isAdmin()) {
      const additionalPaymentTypeList =
        await shipmentAdditionalPaymentService.fetchAdditionalPaymentTypeList();
      this.setState({
        additionalPaymentTypeList,
      });
    }
  }

  handleSuccess = async (reference) => {
    this.setState({ paymentCompleted: true });

    try {
      await shipmentService.paymentInitiated(this.state.shipment.id);
    } catch (e) {}

    // console.log(reference);
  };

  render() {
    return (
      <>
        {this.state.shipment && this.payWithWalletModal()}
        {this.state.paymentCompleted
          ? this.paymentCompleted()
          : this.confirmShipment()}
      </>
    );
  }

  confirmShipment() {
    if (!this.state.shipment) return null;

    const componentProps = {
      ...paystackConfig,
      customer: {
        email: auth.getCurrentUserEmail(),
        // phonenumber: '07064586146',
        name: auth.getCurrentUserFullname(),
      },
      tx_ref: this.state.shipment.transactionReference,
      amount: this.state.totalPaymentAmount,
      currency: "NGN",
      customizations: {
        title: "Book Shipment",
        description: "Please pay for your shipment.",
        logo: "https://cdn.wirepackage.com/files/logo%20bright.png",
      },

      callback: (response) => {
        // console.log("************* > ", response);
        closePaymentModal(); // this will close the modal programmatically
        if (
          response.status === "successful" ||
          response.status === "completed"
        ) {
          toast.success("Payment Completed", toastOptions);
          this.handleSuccess(response);
        }
      },
      onClose: () => {},
      // onSuccess: (reference) => this.handleSuccess(reference),
    };

    const payWithCardProps = {
      ...componentProps,
      payment_options: "card",
      text: (
        <>
          <div>Pay with Card</div>
          <div className="text-sm">
            <em>(Powered by Flutterwave)</em>
          </div>
        </>
      ),
    };

    return (
      <>
        <Row>
          <Col className="col-12 col-lg-6">
            {renderFormPanel({
              title: "Origin Info",
              content: (
                <>
                  <Row>
                    <Col className="col-12 col-md-6 mb-2 font-weight-bold h2">
                      {this.state.shipment.origin.fullname}
                    </Col>
                    <Col className="col-12 col-md-6 mb-2 font-weight-bold">
                      <div className="d-none d-md-block text-right">
                        {this.state.shipment.origin.phoneNumber}
                      </div>
                      <div className="d-block d-md-none">
                        {this.state.shipment.origin.phoneNumber}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-muted text-justify">
                      {this.state.shipment.origin.address},{" "}
                      {this.state.shipment.origin.cityName}
                    </Col>
                  </Row>
                </>
              ),
              bgColor: "bg-white",
              supplimentaryTitle: this.state.shipment.origin.state.replace(
                "_",
                "-"
              ),
            })}
          </Col>
          <Col>
            {renderFormPanel({
              title: "Destination Info",
              content: (
                <>
                  <Row>
                    <Col className="col-12 col-md-6 mb-2 font-weight-bold h2">
                      {this.state.shipment.destination.fullname}
                    </Col>
                    <Col className="col-12 col-md-6 mb-2 font-weight-bold">
                      <div className="d-none d-md-block text-right">
                        {this.state.shipment.destination.phoneNumber}
                      </div>
                      <div className="d-block d-md-none">
                        {" "}
                        {this.state.shipment.destination.phoneNumber}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-muted text-justify">
                      {this.state.shipment.destination.address},{" "}
                      {this.state.shipment.destination.cityName},{" "}
                      {this.state.shipment.destination.postalCode}
                    </Col>
                  </Row>
                </>
              ),
              bgColor: "bg-white",
              supplimentaryTitle: this.state.shipment.destination.state.replace(
                "_",
                "-"
              ),
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            {renderFormPanel({
              title: "Item(s)",
              content: (
                <>
                  {this.state.shipment.items.map((item, index) => {
                    return (
                      <>
                        <div className="d-none d-md-block">
                          <span className="font-weight-bold pr-3">Package</span>
                          {this.state.shipment.items.length > 1 && (
                            <span className="font-weight-bold pr-3">
                              {index + 1} -
                            </span>
                          )}
                          <span className="pull-right pr-3">
                            Weight: {item.weight} Kg,
                          </span>
                          <span className="pull-right">
                            Value: &#8358;
                            {utils.numberFormatter(Number(item.value), 2)}
                          </span>
                        </div>

                        <div className="d-block d-md-none">
                          <span className="font-weight-bold pr-3">Package</span>
                          {this.state.shipment.items.length > 1 && (
                            <span className="font-weight-bold pr-3">
                              {index + 1} -
                            </span>
                          )}
                          <span className="pull-right pr-3">
                            W: {item.weight} Kg,
                          </span>
                          <span className="pull-right">
                            V: &#8358;
                            {utils.numberFormatter(Number(item.value), 2)}
                          </span>
                        </div>
                        {this.loadItemConfirmationTable(item.itemUnits)}
                      </>
                    );
                  })}

                  {this.state.shipment.shipmentDimension && (
                    <>
                      <div className="border-bottom border-light pb-3 mb-3" />
                      <Row>
                        <Col className="col-12 col-md-4">
                          Shipment Dimension:
                        </Col>
                        <Col>
                          <div className="font-weight-bold">
                            {this.state.shipment.shipmentDimension[0]} X{" "}
                            {this.state.shipment.shipmentDimension[1]} X{" "}
                            {this.state.shipment.shipmentDimension[2]}
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              ),
              bgColor: "bg-white",
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            {renderFormPanel({
              title: "Others",
              content: (
                <>
                  <Row>
                    <Col className="col-6 col-sm-4 mb-2">
                      <div className="text-muted">Service Type</div>
                      <div className="font-weight-bold h2">
                        {this.state.shipment.courierRates[0].serviceType}
                      </div>
                    </Col>
                    <Col className="col-6 col-sm-4 mb-2">
                      <div className="text-muted">Insurance Type</div>
                      <div className="font-weight-bold h2">
                        {this.state.shipment.insurance}
                      </div>
                    </Col>
                    <Col className="col-6 col-sm-4 mb-2">
                      <div className="text-muted">
                        Delivery Signature Required
                      </div>
                      <div className="font-weight-bold h2">
                        {this.state.shipment.signatureRequired ? "YES" : "NO"}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-6 col-sm-4 mb-2">
                      <div className="text-muted">Pickup Required</div>
                      <div className="font-weight-bold h2">
                        {this.state.shipment.pickup ? "YES" : "NO"}
                      </div>
                    </Col>
                    {this.state.shipment.preferredPickupDateDesc && (
                      <Col className="col-6 col-sm-4 mb-2">
                        <div className="text-muted">Preferred Pickup Date</div>
                        <div className="font-weight-bold h2">
                          {this.state.shipment.preferredPickupDateDesc}
                        </div>
                      </Col>
                    )}
                  </Row>
                </>
              ),
              bgColor: "bg-white",
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            {renderFormPanel({
              title: "Payment Summary",
              content: (
                <>
                  {parseFloat(this.state.shipment.billableWeight) >
                    parseFloat(this.state.shipment.grossWeight) && (
                    <>
                      <Row>
                        <Col className="mb-2">
                          <div className="text-muted">
                            Shipment Actual Weight
                          </div>
                          <div className="font-weight-bold h2">
                            {this.state.shipment.grossWeight} Kg
                          </div>
                        </Col>
                        <Col className="mb-2">
                          <div className="text-muted">
                            Shipment Dimensional Weight
                          </div>
                          <div className="font-weight-bold h2">
                            {this.state.shipment.billableWeight} Kg
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-2">
                          <div className="text-primary font-weight-bold">
                            Billable Weight
                          </div>
                          <div className="font-weight-bold h2">
                            {this.state.shipment.billableWeight} Kg
                          </div>
                        </Col>
                        <Col className="mb-2"></Col>
                      </Row>
                    </>
                  )}

                  {parseFloat(this.state.shipment.billableWeight) ===
                    parseFloat(this.state.shipment.grossWeight) && (
                    <>
                      <Row>
                        <Col className="mb-2"></Col>
                        <Col className="mb-2">
                          <div className="text-primary font-weight-bold">
                            Billable Weight
                          </div>
                          <div className="font-weight-bold h2">
                            {this.state.shipment.billableWeight} Kg
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}

                  <hr className="my-2" />
                  <Row>
                    <Col className="col-6 col-md-4 mb-2">
                      <div className="text-muted">Shipping Rate</div>
                      <div className="font-weight-bold h2">
                        &#8358;{" "}
                        {utils.numberFormatter(
                          Number(this.state.shipment.shippingRate),
                          2
                        )}
                      </div>
                    </Col>
                    <Col className="col-6 col-md-4 mb-2">
                      <div className="text-muted">Insurance Fee</div>
                      <div className="font-weight-bold h2">
                        {(!this.state.shipment.insuranceFee ||
                          this.state.shipment.insuranceFee == 0) && <>--</>}
                        {this.state.shipment.insuranceFee &&
                          this.state.shipment.insuranceFee != 0 && (
                            <>
                              &#8358;{" "}
                              {utils.numberFormatter(
                                Number(this.state.shipment.insuranceFee),
                                2
                              )}
                            </>
                          )}
                      </div>
                    </Col>
                    <Col className="col-6 col-md-4 mb-2">
                      <div className="text-muted">Signature Assurance</div>
                      <div className="font-weight-bold h2">
                        {!this.state.shipment.signatureServiceFee && <>--</>}
                        {this.state.shipment.signatureServiceFee && (
                          <>
                            &#8358;{" "}
                            {utils.numberFormatter(
                              Number(this.state.shipment.signatureServiceFee),
                              2
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                    <Col className="d-block d-md-none col-6 mb-2">
                      <div className="text-muted">Shipping Materials</div>
                      <div className="font-weight-bold h2">
                        {(!this.state.shipment.shippingBoxFee ||
                          Number(this.state.shipment.shippingBoxFee) === 0) && (
                          <>--</>
                        )}
                        {this.state.shipment.shippingBoxFee &&
                          Number(this.state.shipment.shippingBoxFee) > 0 && (
                            <>
                              &#8358;{" "}
                              {utils.numberFormatter(
                                Number(this.state.shipment.shippingBoxFee),
                                2
                              )}
                            </>
                          )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-none d-md-block col-md-4 mb-2">
                      <div className="text-muted">Shipping Materials</div>
                      <div className="font-weight-bold h2">
                        {(!this.state.shipment.shippingBoxFee ||
                          Number(this.state.shipment.shippingBoxFee) === 0) && (
                          <>--</>
                        )}
                        {this.state.shipment.shippingBoxFee &&
                          Number(this.state.shipment.shippingBoxFee) > 0 && (
                            <>
                              &#8358;{" "}
                              {utils.numberFormatter(
                                Number(this.state.shipment.shippingBoxFee),
                                2
                              )}
                            </>
                          )}
                      </div>
                    </Col>
                    <Col className="col-6 col-md-4 mb-2">
                      <div
                        className={`${
                          this.state.shipment.additionalDocumentsFee &&
                          Number(this.state.shipment.additionalDocumentsFee) !==
                            0
                            ? ""
                            : "d-none"
                        } no-gutter`}
                      >
                        <div className="text-muted">
                          Food Clearance Document(s)
                        </div>
                        <div className="font-weight-bold h2">
                          &#8358;{" "}
                          {utils.numberFormatter(
                            Number(this.state.shipment.additionalDocumentsFee),
                            2
                          )}
                        </div>
                      </div>
                    </Col>
                    {this.state.shipment.additionalPayments.map((payments) => {
                      return (
                        <Col className="col-6 col-md-4">
                          <div className="text-muted">{payments.key}</div>
                          <div className="font-weight-bold h2">
                            &#8358;{" "}
                            {utils.numberFormatter(Number(payments.value), 2)}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                  <Row>
                    <Col className="mt-2 mb-2">
                      <div
                        className={`${
                          this.state.showDiscountInfo ? "" : "d-none"
                        }`}
                      >
                        <div className="text-muted">
                          {this.state.discountInfo.discountRate} Discount
                        </div>
                        <div className="font-weight-bold h2 text-danger">
                          &#8358;{" - "}
                          {utils.numberFormatter(
                            Number(this.state.discountInfo.discountAmount),
                            2
                          )}
                        </div>
                      </div>
                      <Button
                        className={`${this.state.showGiftCode ? "d-none" : ""}`}
                        size="sm"
                        outline
                        color="default"
                        onClick={() => {
                          this.toggleModal("showGiftCode");
                        }}
                      >
                        {this.state.showDiscountInfo
                          ? "Change Gift Code"
                          : "Use Gift Code"}
                      </Button>
                      <form
                        onSubmit={(e) => {
                          this.handleSubmit({
                            e,
                            submitFunction: () => this.applyGiftCode(),
                            formItemNames: ["giftCode"],
                          });
                        }}
                      >
                        <Row
                          className={`${
                            this.state.showGiftCode ? "" : "d-none"
                          } no-gutter`}
                        >
                          <Col className="col-12 ">
                            {this.renderStyledInput({
                              mandatory: true,
                              type: "text",
                              name: "giftCode",
                              placeholder: "Enter Gift Code",
                              size: "sm",
                              label: "Enter Gift Code",
                            })}
                          </Col>
                          <Col className="col-12 d-flex align-items-center">
                            {this.renderSimpleButton({
                              label: "Apply Gift Code",
                              classs: "btn-primary btn-sm",
                              spinnerLabel: "Applying Code",
                              formItemNames: ["giftCode"],
                              busy: this.state.applyingGiftCode,
                              onclick: () => {
                                this.applyGiftCode();
                              },
                            })}
                          </Col>
                        </Row>
                      </form>
                    </Col>
                    <Col className="col-6 col-md-4 mb-2">
                      <div className="text-primary font-weight-bold text-underline">
                        Total Payment Amount
                      </div>
                      <div
                        className={`${
                          this.state.showDiscountInfo ? "" : "d-none"
                        }  mb-2`}
                      >
                        <span className="text-danger text-sm">
                          (discount applied)
                        </span>
                      </div>
                      <div
                        className={`${
                          this.state.showDiscountInfo ? "" : "d-none"
                        }`}
                      >
                        <span className="font-weight-bold h2 p-2 bg-dark text-white">
                          &#8358;{" "}
                          {utils.numberFormatter(
                            Number(
                              this.state.discountInfo.discountedPaymentAmount
                            ),
                            2
                          )}
                        </span>
                      </div>
                      <div
                        className={`${
                          this.state.showDiscountInfo ? "d-none" : ""
                        }  py-2 `}
                      >
                        <span className="font-weight-bold h2 p-2 bg-dark text-white">
                          &#8358;{" "}
                          {utils.numberFormatter(
                            Number(this.state.totalPaymentAmount),
                            2
                          )}
                        </span>
                      </div>
                    </Col>
                    {this.showAdditionalPaymentsForm()}
                  </Row>
                </>
              ),
              bgColor: "bg-white",
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            {renderFormPanel({
              title: "How do you want to pay?",
              content: (
                <>
                  <Row>
                    <Col className="col-12 col-md-4">
                      <div className="text-center mb-3">
                        <Button
                          color="primary"
                          size="lg"
                          block
                          onClick={() => {
                            this.fetchWalletBalance("cash");
                            this.toggleModal("payWithWalletModal");
                          }}
                          className="mb-1 mr-1"
                        >
                          <div>Pay With Wallet</div>
                          <div className="text-sm">
                            <em>(Powered by WirePackage)</em>
                          </div>
                        </Button>
                      </div>
                    </Col>
                    {/* <Col className="col-12 col-md-4">
                      <div className="text-center mb-3">
                        <Button
                          color="primary"
                          size="lg"
                          block
                          onClick={() => {
                            this.fetchWalletBalance("cash_back");
                            this.toggleModal("payWithWalletModal");
                          }}
                          className="mb-1 mr-1"
                        >
                          <div>Pay With Cash Back</div>
                          <div className="text-sm">
                            <em>(Powered by WirePackage)</em>
                          </div>
                        </Button>
                      </div>
                    </Col> */}
                    <Col className="col-12 col-md-4">
                      <ErrorBoundary>
                        <FlutterWaveButton
                          className="btn btn-block btn-lg btn-primary"
                          {...payWithCardProps}
                        />
                      </ErrorBoundary>
                      {/* <PaystackConsumer {...componentProps}>
                      {({ initializePayment }) => (
                        <Button
                          color="primary"
                          size="lg"
                          block
                          outline
                          onClick={() =>
                            initializePayment(this.handleSuccess, handleClose)
                          }
                        >
                          <div>Pay with Card</div>
                          <div className="text-sm">
                            <em>(Powered by PayStack)</em>
                          </div>
                        </Button>
                      )}
                    </PaystackConsumer> */}
                    </Col>
                  </Row>
                </>
              ),
              bgColor: "bg-white",
            })}
            <div className="text-left">
              <Button
                size="lg"
                color="primary"
                onClick={this.props.returnAction}
              >
                <i className="fas fa-undo"></i> {this.props.returnMessage_1}
              </Button>
            </div>
          </Col>
        </Row>
        {/* <div className="text-left border-top pt-2">
          <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              this.nextTab("tab-2");
            }}
            className="float-left"
          >
            Previous
          </Button>
        </div> */}
      </>
    );
  }

  loadItemConfirmationTable(items) {
    return (
      <Card className="mb-0">
        <ToolkitProvider
          data={items}
          keyField="index"
          columns={[
            {
              dataField: "index",
              text: "#",
              sort: true,
              classes: "d-none d-md-block",
              headerClasses: "d-none d-md-block",
            },
            {
              dataField: "description",
              text: "Desc",
              sort: true,
            },
            {
              dataField: "quantity",
              text: "Qty",
              sort: true,
            },
            {
              dataField: "value",
              text: <div className="text-right">Unit Price (&#8358;) </div>,
              sort: true,
              formatter: (cell, row) => {
                return (
                  <div className="text-right">
                    {utils.numberFormatter(Number(row.value), 2)}
                  </div>
                );
              },
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                size="sm"
                ref={(el) => (this.componentRef = el)}
                {...props.baseProps}
                bootstrap4={true}
                // pagination={pagination}
                bordered={false}
                id="react-bs-table"
                striped
                hover
                condensed
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    );
  }

  async applyGiftCode() {
    this.setState({ applyingGiftCode: true });
    try {
      const discountInfo = await shipmentService.applyDiscount(
        this.state.data.giftCode,
        this.state.shipment.id
      );
      this.setState({
        discountInfo,
        totalPaymentAmount: discountInfo.discountedPaymentAmount,
        applyingGiftCode: false,
        showDiscountInfo: true,
        showGiftCode: false,
      });
    } catch (ex) {
      this.setState({ applyingGiftCode: false });
    }
  }

  async addAdditionalPayment() {
    this.setState({ applyingAdditionalPayment: true });
    try {
      const response =
        await shipmentAdditionalPaymentService.updateAdditionalPayment({
          key: this.state.selectedAdditionalPaymentType,
          value: this.state.data.additionalPaymentAmount,
          id: this.state.shipment.id,
        });

      const updatedShipment = await shipmentService.loadIncompleteBookingDetail(
        this.state.shipment.id
      );

      toast.success(response.message);

      const discountInfo = this.state.discountInfo;
      discountInfo.discountedPaymentAmount = updatedShipment.paymentAmount;
      const totalPaymentAmount = updatedShipment.paymentAmount;

      this.setState({
        applyingAdditionalPayment: false,
        shipment: updatedShipment,
        totalPaymentAmount,
        discountInfo,
      });
    } catch (ex) {
      this.setState({ applyingAdditionalPayment: false });
    }
  }

  submitShipment = async () => {
    this.setState({ submitShipmentInProgress: true });

    let {
      itemList,
      data,
      insuranceType,
      packageArrivalType,
      preferredPickupDate,
    } = this.state;

    const shipment = {
      items: itemList,
      insurance: insuranceType,
      pickup: packageArrivalType === "pickup",
      origin: {
        fullname: data.senderFullname,
        phoneNumber: data.senderPhoneNumber,
        email: data.senderEmail,
        address: data.senderAddress,
        country: data.senderCountry.value,
        state: data.senderCountry.value + "_" + data.senderState.value,
        city: data.senderCity.value,
        cityName: data.senderCity.label,
        saveInAddressBook: data.saveOriginAddress,
      },
      destination: {
        fullname: data.recipientFullname,
        phoneNumber: data.recipientPhoneNumber,
        email: data.recipientEmail,
        address: data.recipientAddress,
        country: data.recipientCountry.value,
        state: data.recipientCountry.value + "_" + data.recipientState.value,
        city: data.recipientCity.value,
        postalCode: data.recipientPostalCode,
        cityName: data.recipientCity.label,
        saveInAddressBook: data.saveRecipientAddress,
      },
      preferredPickupDate:
        data.usePreferredPickupDate && preferredPickupDate
          ? preferredPickupDate.format(dateFormat)
          : null,
      preferredPickupDateDesc:
        data.usePreferredPickupDate && preferredPickupDate
          ? preferredPickupDate.format("dddd, MMM D, YYYY")
          : null,
      transactionReference: this.state.shipment
        ? this.state.shipment.transactionReference
        : null,
      id: this.state.shipment ? this.state.shipment.id : 0,
    };

    try {
      const shipmentResponse = await shipmentService.bookShipment(shipment);
      this.setState({
        shipment: shipmentResponse,
        totalPaymentAmount: Number(shipmentResponse.paymentAmount),
        discountInfo: {},
        applyingGiftCode: false,
        showDiscountInfo: false,
        showGiftCode: false,
      });
      this.nextTab("tab-3");
    } catch (ex) {}
    this.setState({ submitShipmentInProgress: false });
  };

  payWithWalletModal = () => {
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.payWithWalletModal}
        toggle={() => this.toggleModal("payWithWalletModal")}
        backdrop="static"
      >
        <div className="modal-header bg-primary">
          <h6
            className="modal-title text-secondary"
            id="modal-title-notification"
          >
            {this.state.fetchedWalletBalanceType === "cash"
              ? "Pay With Wallet Balance"
              : this.state.fetchedWalletBalanceType === "cash_back"
              ? "Pay With Cash Back Balance"
              : ""}
          </h6>
          <button
            aria-label="Close"
            className="close text-secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("payWithWalletModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        {this.displayWalletView()}
      </Modal>
    );
  };

  displayWalletView() {
    if (this.state.fetchingWalletBalance) {
      return (
        <div className="my-5">
          {showLoadingRecordsMessage("Fetching your wallet balance...")}
        </div>
      );
    } else if (this.state.fetchedWalletBalance) {
      let balance = 0,
        balanceTitle = "",
        errorMessage = "",
        isOrganizationWallet = false,
        creditLimit = 0;
      let paymentAmount = Number(this.state.totalPaymentAmount);
      if (this.state.fetchedWalletBalanceType === "cash") {
        balance = Number(this.state.wallet.cashBalance);
        balanceTitle = "Wallet Balance";
        isOrganizationWallet = this.state.wallet.organizationWallet;
        creditLimit = this.state.wallet.creditLimit;
        errorMessage = (
          <Alert color="danger" className="text-center">
            <div className="alert-body">
              Insufficient Wallet balance! Please fund your wallet and retry or
              use another payment method.
            </div>
          </Alert>
        );
      } else if (this.state.fetchedWalletBalanceType === "cash_back") {
        balance = Number(this.state.wallet.cashBackBalance);
        balanceTitle = "Cash Back Balance";
        errorMessage = (
          <Alert color="danger" className="text-center">
            <div className="alert-body">
              Insufficient Cash Back balance. Please use another payment method.
            </div>
          </Alert>
        );
      } else {
        return (
          <>
            <div className="modal-body p-2 text-center">
              Unknown balance type selected.
            </div>
          </>
        );
      }

      return (
        <>
          <div className="modal-body p-2 text-center">
            <span className="text-muted">{balanceTitle}</span>
            <div className="font-weight-bold h1">
              &#8358; {utils.numberFormatter(balance, 2)}
            </div>
            {(balance > 0 && balance >= paymentAmount) ||
            (isOrganizationWallet && creditLimit >= paymentAmount - balance) ? (
              <div className="mt-2">
                Book your shipment by clicking on the button below.
              </div>
            ) : (
              <div className="mt-2">{errorMessage}</div>
            )}
          </div>
          {(balance > 0 && balance >= paymentAmount) ||
          (isOrganizationWallet && creditLimit >= paymentAmount - balance) ? (
            <div className="modal-footer text-center">
              <Button
                block
                className="ml-auto"
                color="success"
                type="button"
                onClick={() => {
                  this.payWithBalance();
                }}
                disabled={this.state.payWithWalletBalanceInProgress}
              >
                <span
                  className={
                    this.state.payWithWalletBalanceInProgress ? "" : "d-none"
                  }
                >
                  <Spinner type="border" size="sm"></Spinner> Making payment...
                </span>
                <span
                  className={
                    this.state.payWithWalletBalanceInProgress ? "d-none" : ""
                  }
                >
                  Pay &#8358; {utils.numberFormatter(paymentAmount, 2)}
                </span>
              </Button>
            </div>
          ) : (
            <div className="modal-footer text-center">
              <Button
                block
                className="ml-auto"
                color="primary"
                type="button"
                onClick={() => this.toggleModal("payWithWalletModal")}
              >
                Close
              </Button>
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          <div className="modal-body p-2 text-center">
            Could not load your wallet balance. Please check your network
            connectivity.
          </div>
        </>
      );
    }
  }

  fetchWalletBalance = async (fetchedWalletBalanceType) => {
    this.setState({
      fetchedWalletBalanceType,
      fetchingWalletBalance: true,
      fetchedWalletBalance: false,
    });
    try {
      const wallet = await walletService.fetchWallet();
      this.setState({
        wallet,
        fetchingWalletBalance: false,
        fetchedWalletBalance: true,
      });
    } catch (e) {
      this.setState({ fetchingWalletBalance: false });
    }
  };

  payWithBalance = async () => {
    this.setState({ payWithWalletBalanceInProgress: true });
    let response = null;
    try {
      if (this.state.fetchedWalletBalanceType === "cash") {
        response = await shipmentService.payWithWallet(
          this.state.shipment.transactionReference
        );
      } else if (this.state.fetchedWalletBalanceType === "cash_back") {
        response = await shipmentService.payWithCashBack(
          this.state.shipment.transactionReference
        );
      } else {
        return;
      }

      toast.success(response.message);
      this.setState({
        paymentCompleted: true,
        payWithWalletBalanceInProgress: false,
      });
      this.toggleModal("payWithWalletModal");
    } catch (e) {
      this.setState({
        payWithWalletBalanceInProgress: false,
      });
    }
  };

  paymentCompleted() {
    this.scrollToTheTop();

    return (
      <div className="text-center">
        <h1>Thank you for shipping with WirePackage!</h1>
        <br />
        <h2>Tracking Number: {this.state.shipment.trackingNumber}</h2>
        <br />
        <h2>Waybill will be sent to your email shortly.</h2>
        <br />
        <br />
        <Button
          className="ml-auto"
          color="primary"
          type="button"
          onClick={this.props.returnAction}
        >
          {this.props.returnMessage_2}
        </Button>
      </div>
    );
  }

  scrollToTheTop() {
    //scroll up window
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  showAdditionalPaymentsForm() {
    if (this.isAdmin()) {
      return (
        <Col className="col-6 col-md-4 mb-2 mt-4">
          {this.renderSelect2({
            mandatory: true,
            type: "text",
            name: "additionalPaymentTypeList",
            placeholder: "Select Extra Payment",
            options: this.state.additionalPaymentTypeList,
            onchange: async (selectedItem) => {
              this.setState({
                selectedAdditionalPaymentType: selectedItem.value,
              });
            },
          })}
          {this.renderStyledInput({
            mandatory: true,
            type: "text",
            name: "additionalPaymentAmount",
            icon: "fas fa-money-bill-wave",
            placeholder: "Enter Extra Amount",
            size: "sm",
            label: "Enter Extra Amount",
          })}
          {this.renderSimpleButton({
            label: "Add Payment",
            classs: "btn-primary btn-sm",
            spinnerLabel: "Adding Payment",
            formItemNames: [
              "additionalPaymentTypeList",
              "additionalPaymentAmount",
            ],
            busy: this.state.applyingAdditionalPayment,
            onclick: () => {
              this.addAdditionalPayment();
            },
          })}
        </Col>
      );
    }
  }

  isAdmin() {
    return (
      auth.getCurrentUserRole() === "ROOT" ||
      auth.getCurrentUserRole() === "ADMIN" ||
      auth.getCurrentUserRole() === "MANAGER"
    );
  }
}

export default CompleteBooking;
